import React, {useState, useContext} from 'react'
import { LanguageContext } from '../context/languageContext'
import useToggleState from '../hooks/useToggleState'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import '../styles/components/navbar.scss'

function LanguageDropdown() {
    const [languages] = useState(['GEO', 'ENG'])
    const [isOpen, toggleOpen] = useToggleState()
    const { language, handleChange } = useContext(LanguageContext)
    const handleClick = event => {
        handleChange(event.target.textContent)
        window.localStorage.setItem('language', JSON.stringify(event.target.textContent))
    }
    return (
        <div className='language-dropdown' onClick={toggleOpen}>
            <div className='language-dropdown-header'>
                <div>{language}</div>
                <FontAwesomeIcon icon={faAngleDown} className={isOpen ? 'rotate-lft' : 'rotate-rght'}/>
            </div>
            {isOpen && <ul className={isOpen ? 
                         'language-dropdown-list dropdown_animated_up' 
                       : 'language-dropdown-list'}>
                {languages.map((lan, indx) => (
                    <li key={indx} onClick={e => handleClick(e)}>
                        {lan}
                    </li>
                ))}
            </ul>}
        </div>
    )
}

export default LanguageDropdown;
