import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import '../styles/components/footer.scss'
import '../styles/components/scroll.scss'

function ScrollUpButton() {
    return (
        <div className='scroll-Up' onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
            <FontAwesomeIcon icon={faChevronUp}/>
        </div>
    )
}

export default ScrollUpButton
