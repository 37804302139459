import React, {useContext, useState} from 'react'
import { useDispatch } from 'react-redux'
import { LanguageContext } from '../context/languageContext'
import { getEventsList } from '../actions/eventsActions'
import translate from '../language/translate'
import '../styles/components/searchbox.scss'


function SearchBox({ history }) {
 const { language } = useContext(LanguageContext)
 const { search, searchTitle } = translate[language]
 const [keyword, setKeyword] = useState('')
 const dispatch = useDispatch()
 const searchHandler = e => {
     e.preventDefault()
     if(keyword.trim()){
        history.push(`/search/${keyword}`)
     } else {
        history.push('/events')
     }
     dispatch(getEventsList(keyword, language))
     setKeyword('')
 }
  return (
    <div className='searchbox'>
        <form onSubmit={searchHandler} className='searchbox-form'>
            <input type='text' value={keyword} onChange={e => setKeyword(e.target.value)} placeholder={searchTitle}/>
            <button type='submit'>{search}</button>
        </form>
    </div>
  )
}

export default SearchBox
