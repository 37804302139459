import { 
    BLOG_CREATE_FAIL,
    BLOG_CREATE_REQUEST,
    BLOG_CREATE_RESET,
    BLOG_CREATE_SUCCESS,
    BLOG_DETAIL_FAIL,
    BLOG_DETAIL_REQUEST,
    BLOG_DETAIL_RESET,
    BLOG_DETAIL_SUCCESS,
    BLOG_LIST_FAIL, 
    BLOG_LIST_REQUEST, 
    BLOG_LIST_SUCCESS, 
    BLOG_REMOVE_FAIL, 
    BLOG_REMOVE_REQUEST,
    BLOG_REMOVE_SUCCESS,
    BLOG_UPDATE_FAIL,
    BLOG_UPDATE_REQUEST,
    BLOG_UPDATE_RESET,
    BLOG_UPDATE_SUCCESS
} from "../constants/blogsConstants";

export const blogListReducer = (state = { blogs: []}, action) => {
  switch(action.type){
      case BLOG_LIST_REQUEST :
          return {
              loading: true,
              blogs: []
          }
      case BLOG_LIST_SUCCESS :
          return {
              loading: false,
              blogs: action.payload.blogs,
     
          }
      case BLOG_LIST_FAIL :
          return {
              loading: false,
              error: action.payload
          }
      default :
         return state
  }
}

export const singleBlogReducer = ( state = { blog : {}}, action) => {
    switch(action.type){
        case BLOG_DETAIL_REQUEST : 
          return {
              loading: true,
              ...state,
          }
        case BLOG_DETAIL_SUCCESS : 
          return {
              loading: false,
              blog: action.payload
          }
        case BLOG_DETAIL_FAIL:
          return {
              loading: false,
              error: action.payload
          }
        case BLOG_DETAIL_RESET:
          return {}
        default :
           return state
    }
  }

export const removeBlogReducer = (state = {}, action) => {
    switch(action.type){
        case BLOG_REMOVE_REQUEST : 
          return {
              loading: true,
          }
        case BLOG_REMOVE_SUCCESS :
          return {
              loading: false,
              success: true,
          }
        case BLOG_REMOVE_FAIL :
          return {
              loading: false, error: action.payload
          }
        default:
            return state
    }
}

export const createBlogReducer = (state = {}, action) => {
    switch(action.type){
        case BLOG_CREATE_REQUEST : 
          return {
              loading: true,
          }
        case BLOG_CREATE_SUCCESS :
          return {
              loading: false,
              success: true,
          }
        case BLOG_CREATE_RESET :
            return {

            }
        case BLOG_CREATE_FAIL :
          return {
              loading: false, error: action.payload
          }
        default:
            return state
    }
}

export const updateBlogReducer = (state = { blog: {}}, action) => {
    switch (action.type) {
        case BLOG_UPDATE_REQUEST:
          return { loading: true }
        case BLOG_UPDATE_SUCCESS:
          return { loading: false, success: true, blog: action.payload }
        case BLOG_UPDATE_FAIL:
          return { loading: false, error: action.payload }
        case BLOG_UPDATE_RESET:
          return { blog: {} }
        default:
          return state
    }
}