import axios from "axios"
import { 
    EVENT_CREATE_FAIL,
    EVENT_CREATE_REQUEST,
    EVENT_CREATE_REVIEW_FAIL,
    EVENT_CREATE_REVIEW_REQUEST,
    EVENT_CREATE_REVIEW_SUCCESS,
    EVENT_CREATE_SUCCESS,
    EVENT_DETAIL_FAIL, 
    EVENT_DETAIL_REQUEST, 
    EVENT_DETAIL_SUCCESS, 
    EVENT_LIST_FAIL, 
    EVENT_LIST_REQUEST, 
    EVENT_LIST_SUCCESS, 
    EVENT_REMOVE_FAIL, 
    EVENT_REMOVE_REQUEST,
    EVENT_REMOVE_SUCCESS,
    EVENT_UPDATE_FAIL,
    EVENT_UPDATE_REQUEST,
    EVENT_UPDATE_SUCCESS
} from "../constants/eventsConstants"

export const getEventsList = (keyword = '', language = 'GEO') => async(dispatch) => {
   try {
     dispatch({
         type: EVENT_LIST_REQUEST
     })

     const { data } = await axios.get(`/api/events/?keyword=${keyword}&language=${language}`)

     dispatch({
         type: EVENT_LIST_SUCCESS,
         payload: data
     })
   }
   catch(error){
    dispatch({
        type: EVENT_LIST_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
   }
}

export const getSingleEvent = (id) => async(dispatch) => {
    try {
      dispatch({
          type: EVENT_DETAIL_REQUEST
      })
 
      const { data } = await axios.get(`/api/events/${id}`)
 
      dispatch({
          type: EVENT_DETAIL_SUCCESS,
          payload: data
      })
    }
    catch(error){
     dispatch({
         type: EVENT_DETAIL_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message
     })
    }
}


export const removeEventAction = (id) => async(dispatch, getState) => {
    try {
      dispatch({
          type: EVENT_REMOVE_REQUEST
      })

      const { loginUser: { user }} = getState()

      const config = {
        headers : {
         'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      }
 
      await axios.delete(`/api/events/${id}`, config)
 
      dispatch({type: EVENT_REMOVE_SUCCESS})
    }
    catch(error){
     dispatch({
         type: EVENT_REMOVE_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message
     })
    }
}

export const createEventAction = (titleEng, titleGeo, textEng, textGeo, image) => async(dispatch, getState) => {
    try {
      dispatch({
          type: EVENT_CREATE_REQUEST
      })

      const { loginUser: { user }} = getState()

      const config = {
        headers : {
         'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      }
 
       await axios.post(`/api/events/`, {titleEng, titleGeo, textEng, textGeo, image }, config)
 
      dispatch({
          type: EVENT_CREATE_SUCCESS
        })
    }
    catch(error){
     dispatch({
         type: EVENT_CREATE_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message
     })
    }
}

export const updateEventAction = (eventObj) => async(dispatch, getState) => {
    try {
      dispatch({
          type: EVENT_UPDATE_REQUEST
      })

      const { loginUser: { user }} = getState()

      const config = {
        headers : {
         'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      }
 
       const {data} = await axios.put(`/api/events/${eventObj._id}`, eventObj, config)
 
      dispatch({ type: EVENT_UPDATE_SUCCESS })
      dispatch({ type: EVENT_DETAIL_SUCCESS, payload: data })
    }
    catch(error){
     dispatch({
         type: EVENT_UPDATE_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message
     })
    }
}

export const createEventReviewAction = (id, comment) => async(dispatch, getState) => {
  try {
    dispatch({
        type: EVENT_CREATE_REVIEW_REQUEST
    })

    const { loginUser: { user }} = getState()

    const config = {
      headers : {
       'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`
      }
    }

     await axios.post(`/api/events/${id}/reviews`, comment, config)

    dispatch({ type: EVENT_CREATE_REVIEW_SUCCESS})
  }
  catch(error){
   dispatch({
       type: EVENT_CREATE_REVIEW_FAIL,
       payload: error.response && error.response.data.message ? error.response.data.message : error.message
   })
  }
}