import React, {useEffect, useContext, useState } from 'react'
import {LanguageContext} from '../context/languageContext'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { userDetailAction, userUpdateAction } from '../actions/userActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import Loader from '../components/Loader'
import Alert from '../components/Alert'
import '../styles/screens/user.scss'
import translate from '../language/translate'
import { USER_UPDATE_RESET } from '../constants/userConstants'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

function UserDetailScreen({history}) {
  const {language} = useContext(LanguageContext)
  const { 
    profile, 
    update, 
    _name, 
    _email, 
    _password, 
    enterPassword, 
    _confirmPassword, 
    matchPasswrod,
    profileUpdate,
} = translate[language]
const [name, setName] = useState('')
const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const [confirmPassword, setConfirmPassword] = useState('')
const [message, setMessage] = useState(null)

const dispatch = useDispatch()
  
const loginUser = useSelector(state => state.loginUser)
const { user } = loginUser

const userDetail = useSelector(state => state.userDetail)
const { loading, error, userInfo } = userDetail

const userUpdate = useSelector(state => state.userUpdate)
const { success, loading: updateLoading } = userUpdate


useEffect(() => {
     if(!user){
         history.push('/login')
     } else {
         if(!userInfo || !userInfo.name){
           dispatch(userDetailAction('profile'))
         } else {
           setName(userInfo.name)
           setEmail(userInfo.email)
        }
     }
}, [dispatch, user, history, userInfo, success])

const handleSubmit = e => {
    e.preventDefault();
      if(password !== confirmPassword){
         setMessage(matchPasswrod)
      } else {
        dispatch(userUpdateAction({id: userInfo._id, name, email, password}))
        setTimeout(() => {
          dispatch({type: USER_UPDATE_RESET})
          dispatch(userDetailAction('profile'))
        }, 4000)
        setMessage(null)
    }
}

const historyPath = useHistory();

const goToPreviousPath = () => {
       historyPath.goBack()
}
  return (
      <>
      <NavBar/>
        <div className='userDetail'>
            <FontAwesomeIcon className='userDetail-goBack' icon={faArrowAltCircleLeft} onClick={goToPreviousPath}/> 
            <div className='userDetail-line'></div>
            <h1>{userInfo.name}'s {profile}</h1>
            {error && <Alert text={error} success={false}/>}
            {message && <Alert text={message} success={false}/>}
            {success && <Alert text={profileUpdate} success={true}/>}
            {loading || updateLoading ? <Loader/> :
            <div className='userDetail-container'>
                <form onSubmit={handleSubmit} className='userDetail-container-form'>
                    <label htmlFor='name'>{_name}</label>
                    <input id='name' value={name} type='text' onChange={e => setName(e.target.value)}/>

                    <label htmlFor='email'>{_email}</label>
                    <input id='email' value={email} type='text' onChange={e => setEmail(e.target.value)}/>

                    <label htmlFor='password'>{_password}</label>
                    <input 
                        id='password' 
                        placeholder={enterPassword}
                        value={password} 
                        type='password' 
                        onChange={e => setPassword(e.target.value)}
                    />

                    <label htmlFor='consfirmPassword'>{_confirmPassword}</label>
                    <input id='confirmPassword' 
                      value={confirmPassword} 
                      placeholder={_confirmPassword}
                      type='password' 
                      onChange={e => setConfirmPassword(e.target.value)}/>
                      <button type='submit'>{update}</button>
                </form>
            </div>}
        </div>
        <Footer/>
      </>
    )
}

export default UserDetailScreen
