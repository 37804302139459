import obstGeo from '../staticImages/obstGeo.webp'
import aboutImage from '../staticImages/aboutImage.webp'

export const obstacleGeo = {
    image : obstGeo,
    textEngFirst: `Lorem Ipsum is simply dummy text of the printing and 
    typesetting industry. Lorem Ipsum has been the industry's 
    standard dummy text ever since the 1500s, when an unknown 
    printer took a galley of type and scrambled it to make a type 
    specimen book. It has survived not only five centuries, 
    but also the leap into electronic typesetting, remaining 
    essentially unchanged. It was popularised in the 1960s 
    with the release of Letraset sheets containing Lorem Ipsum 
    passages, and more recently with desktop 
    publishing software like Aldus PageMaker including versions of Lorem Ipsum`,
    textGeoFirst: `უბრალოდ დაბეჭდილი ტექსტია და
    საბეჭდი ინდუსტრია. Lorem Ipsum იყო ინდუსტრიის
    სტანდარტული ცუდი ტექსტი 1500-იანი წლებიდან, უცნობი
    პრინტერმა აიღო გალერეა ტიპისთვის და შეაზავა მას ტიპის შესაქმნელად
    ნიმუშების წიგნი. მან გადარჩა არა მხოლოდ ხუთი საუკუნე,
    ასევე ნახტომი ელექტრონულ ტიპაჟირებაში, დარჩენილი
    არსებითად უცვლელი. მისი პოპულარიზაცია მოხდა 1960-იან წლებში
    Lorem Ipsum- ის შემცველი Letraset ფურცლების გამოშვებით
    გადასასვლელები და ახლახანს დესკტოპით
    საგამომცემლო პროგრამული უზრუნველყოფა, როგორიცაა Aldus PageMaker, Lorem Ipsum- ის ვერსიების ჩათვლით`,
    textEngSecond: `specimen book. It has survived not only five centuries, 
    but also the leap into electronic typesetting, remaining 
    essentially unchanged. It was popularised in the 1960s 
    with the release of Letraset sheets containing Lorem Ipsum 
    passages, and more recently with desktop 
    publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
    textGeoSecond: `ნიმუშების წიგნი. მან გადარჩა არა მხოლოდ ხუთი საუკუნე,
    ასევე ნახტომი ელექტრონულ ტიპაჟირებაში, დარჩენილი
    არსებითად უცვლელი. მისი პოპულარიზაცია მოხდა 1960-იან წლებში
    Lorem Ipsum- ის შემცველი Letraset ფურცლების გამოშვებით
    გადასასვლელები და ახლახანს დესკტოპით
    საგამომცემლო პროგრამული უზრუნველყოფა, როგორიცაა Aldus PageMaker, Lorem Ipsum- ის ვერსიების ჩათვლით.`
}

export const obstacleSport = {
   textEng : ` Lorem Ipsum is simply dummy text of the printing and 
   typesetting industry. Lorem Ipsum has been the industry's 
   standard dummy text ever since the 1500s, when an unknown 
   printer took a galley of type and scrambled it to make a type 
   specimen book. It has survived not only five centuries, 
   but also the leap into electronic typesetting, remaining 
   essentially unchanged. It was popularised in the 1960s 
   with the release of Letraset sheets containing Lorem Ipsum 
   passages, and more recently with desktop 
   publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
   textGeo: `უბრალოდ დაბეჭდილი ტექსტია და
   საბეჭდი ინდუსტრია. Lorem Ipsum იყო ინდუსტრიის
   სტანდარტული ცუდი ტექსტი 1500-იანი წლებიდან, უცნობი
   პრინტერმა აიღო გალერეა ტიპისთვის და შეაზავა მას ტიპის შესაქმნელად
   ნიმუშების წიგნი. მან გადარჩა არა მხოლოდ ხუთი საუკუნე,
   ასევე ნახტომი ელექტრონულ ტიპაჟირებაში, დარჩენილი
   არსებითად უცვლელი. მისი პოპულარიზაცია მოხდა 1960-იან წლებში
   Lorem Ipsum- ის შემცველი Letraset ფურცლების გამოშვებით
   გადასასვლელები და ახლახანს დესკტოპით
   საგამომცემლო პროგრამული უზრუნველყოფა, როგორიცაა Aldus PageMaker, Lorem Ipsum- ის ვერსიების ჩათვლით.`
}

export const aboutContent = {
    image: aboutImage,
    aboutTextGeo: 'ჩვენ შესახებ',
    aboutTextEng: 'About Us',
    textGeo: `პოპულარული რწმენის საწინააღმდეგოდ, 
    Lorem Ipsum არ არის უბრალოდ შემთხვევითი ტექსტი. 
    მას აქვს ფესვები კლასიკურ ლათინურ ლიტერატურაში 
    ძვ. წ. 45 წლიდან, რაც 2000 წელზე მეტი ხნისაა. რიჩარდ 
    მაკკლინტოკმა, ვირჯინიის ჰამპდენ-სიდნეის კოლეჯის ლათინურმა 
    პროფესორმა, Lorem Ipsum პასაჟიდან მოიძია ლათინური ერთ-ერთი 
    უფრო ბუნდოვანი სიტყვა, consectetur, და ამ სიტყვების ციტირებაში
     შესულ კლასიკურ ლიტერატურაში აღმოაჩინა საეჭვო წყარო. 
     Lorem Ipsum მოდის ციცერონის მიერ დაწერილი "de Finibus 
     Bonorum et Malorum" (სიკეთისა და ბოროტების უკიდურესობები) 
     1.10.32 და 1.10.33 ნაწილებიდან, დაწერილი ჩვენს 
     წელთაღრიცხვამდე 45 წელს. ეს წიგნი არის ეთიკის თეორიის 
     ტრაქტატი, რომელიც ძალიან პოპულარულია რენესანსის პერიოდში. 
     Lorem Ipsum- ის პირველი სტრიქონი, "Lorem ipsum dolor 
     sit amet ..", მოდის სტრიქონში 1.10.32. Lorem Ipsum– ის 
     სტანდარტული ბლოკი, რომელიც გამოიყენება 1500 – იანი წლებიდან, 
     ქვემოთ მოცემულია მსურველთათვის. ციცერონის "de Finibus 
     Bonorum et Malorum" - ის 1.10.32 და 1.10.33 სექციები 
     ასევე გადმოცემულია მათი ზუსტი თავდაპირველი ფორმით, რომელსაც 
    ახლავს ჰ. რაკჰამის 1914 წლის თარგმანიდან ინგლისურენოვან ვერსიებს.`,
    textEng: `Contrary to popular belief, Lorem Ipsum is not simply random text. 
    It has roots in a piece of classical Latin literature from 45 BC, 
    making it over 2000 years old. Richard McClintock, a Latin professor 
    at Hampden-Sydney College in Virginia, looked up one of the more obscure 
    Latin words, consectetur, from a Lorem Ipsum passage, and going through 
    the cites of the word in classical literature, discovered the 
    undoubtable source. Lorem Ipsum comes from sections 1.10.32 
    and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) 
    by Cicero, written in 45 BC. This book is a treatise on the theory 
    of ethics, very popular during the Renaissance. The first line of 
    Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
    The standard chunk of Lorem Ipsum used since the 1500s is reproduced 
    below for those interested. Sections 1.10.32 and 1.10.33 from "de 
    Finibus Bonorum et Malorum" by Cicero are also reproduced in their 
    exact original form, accompanied by English versions from the 1914 
    translation by H. Rackham.`
}