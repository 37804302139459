import React, {useEffect, useContext, useState } from 'react'
import {LanguageContext} from '../context/languageContext'
import { useDispatch, useSelector } from 'react-redux'
import { userDetailAction, userAdminUpdateAction } from '../actions/userActions'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import Alert from '../components/Alert'
import '../styles/screens/user.scss'
import translate from '../language/translate'
import { USER_ADMIN_UPDATE_RESET } from '../constants/userConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

function UserEditScreen({history, match}) {
  const {language} = useContext(LanguageContext)
  const { 
    profile, 
    update, 
    _name, 
    _email,
} = translate[language]
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  const dispatch = useDispatch()

  const loginUser = useSelector(state => state.loginUser)
  const {user} = loginUser 

  const userDetail = useSelector(state => state.userDetail)
  const { loading, error, userInfo } = userDetail

  const userAdminUpdate = useSelector(state => state.userAdminUpdate)
  const { loading: updateLoading, success } = userAdminUpdate

  useEffect(() => {
      if(!user.isAdmin){
          history.push('/')
      }
    if(success){
        dispatch({type: USER_ADMIN_UPDATE_RESET})
        history.push('/userList')
     } else if(userInfo){
         if(!userInfo || userInfo._id !== match.params.id){
             dispatch(userDetailAction(match.params.id))
         } else {
            setName(userInfo.name)
            setEmail(userInfo.email)
            setIsAdmin(userInfo.isAdmin)
         }
     }
  }, [dispatch, history, userInfo, match.params.id, success, user])

  const handleSubmit = e => {
      e.preventDefault();
      dispatch(userAdminUpdateAction({_id: match.params.id, name, email, isAdmin }))

  }
    return (
        <>
        <NavBar/>
        <div className='userDetail'>
            <h1>{userInfo.name}'s {profile}</h1>
            <Link to='/userList'><FontAwesomeIcon icon={faChevronLeft}/></Link>
            {error && <Alert text={error} success={false}/>}
            {loading || updateLoading ? <Loader/> :
            <div className='userDetail-container'>
                <form onSubmit={handleSubmit} className='userDetail-container-form'>
                    <label htmlFor='name'>{_name} :{' '} {userInfo && userInfo.name}</label>
                    <input id='name' value={name} type='text' onChange={e => setName(e.target.value)}/>

                    <label htmlFor='email'>{_email} :{' '} {userInfo && userInfo.email}</label>
                    <input id='email' value={email} type='text' onChange={e => setEmail(e.target.value)}/>

                    <label htmlFor='isAdmin'>admin</label>
                    <input id='isAdmin' 
                        value={email} type='checkbox' 
                        checked={isAdmin} 
                        onChange={e => setIsAdmin(e.target.checked)}/>

                    <button type='submit'>{update}</button>
                </form>
            </div>}
        </div>
     <Footer/>
    </>
    )
}

export default UserEditScreen

