import React, { useContext } from 'react'
import { LanguageContext } from '../context/languageContext'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import '../styles/screens/about.scss'
import { aboutContent } from '../helpers/staticContent'

function AboutScreen({location}) {
   const { language } = useContext(LanguageContext)
   const { pathname } = location
   const { image, aboutTextEng, aboutTextGeo, textEng, textGeo } = aboutContent

    return (
       <>
        <NavBar pathname={pathname}/>
        <div className='about'>
           <div className='about-container'>
              <div className='about-container-top'>
                 <img src={image} className="about-container-top_img" alt='about'/>
              </div>
              <div className='about-container-middle'>
                  <div className='about-container-middle-container'>
                     <div className='about-container-middle-container_line'></div>
                    <h2 className={language === 'GEO' ? 'geoFont-Login' : ''}>
                       {language === 'GEO' ? aboutTextGeo : aboutTextEng }
                    </h2>
                    <p>
                       {language === 'GEO' ? textGeo : textEng }
                    </p>
                 </div>
              </div>
           </div>
         <Footer/>
        </div>
    </>
  )
}

export default AboutScreen
