import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../components/Alert'
import { updateBlogAction } from '../actions/blogsActions'
import Loader from '../components/Loader'
import {singleBlogAction} from '../actions/blogsActions'
import { BLOG_UPDATE_RESET } from '../constants/blogsConstants'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { uploadImage } from '../helpers/upload'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

function AdminBlogEditScreen({match, history}) {
 const blogId = match.params.id
 const [titleGeo, setTitleGeo] = useState('')
 const [titleEng, setTitleEng] = useState('')
 const [textGeo, setTextGeo] = useState('')
 const [textEng, setTextEng] = useState('')
 const [image, setImage] = useState('')
 const [uploading, setUploading] = useState(false)

 const dispatch = useDispatch()
 const loginUser = useSelector(state => state.loginUser)
 const { user } = loginUser

 const singleBlog = useSelector(state => state.singleBlog)
 const {loading, blog} = singleBlog

 const updateBlog = useSelector(state => state.updateBlog)
 const { success, error} = updateBlog


 useEffect(() => {
     if(user && user.isAdmin) {
         if(success){
             history.push('/blogList')
             dispatch({type: BLOG_UPDATE_RESET})
         } else if(blog){
            if(!blog.titleEng|| blog._id !== blogId) {
                dispatch(singleBlogAction(blogId))
            } else {
                setTitleEng(blog.titleEng)
                setTitleGeo(blog.titleGeo)
                setTextEng(blog.textEng)
                setTextGeo(blog.textGeo)
                setImage(blog.image)
            }
        }
     } else {
         history.push('/')
     }
 }, [history, user, blog, dispatch, blogId, success])

 const handleSubmit = e => {
     e.preventDefault()
     dispatch(updateBlogAction({
         _id: blogId,
         titleEng,
         titleGeo,
         textEng,
         textGeo,
         image
     }))
 }

 const handleUpload = async(e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    uploadImage(formData, setImage, setUploading)
 }
    return (
      <>
      <NavBar/>
        <div className="form_blog">
            <h1>Blog Edit Screen</h1>
            {error && <Alert text={error} success={false}/>}
            {loading ? <Loader/> :
            <div className='form_blog-container'>
                  <Link to='/blogList' className='form_blog-container-back'>
                     <FontAwesomeIcon icon={faChevronLeft}/>
                  </Link>
                <form onSubmit={handleSubmit} className="form_blog-container-form">

                    <label htmlFor='titleGeo'>ქართული სათაური</label>
                    <input id='titleGeo' type="text" value={titleGeo} 
                           placeholder='ქართული სათაური' required onChange={(e) => setTitleGeo(e.target.value)}/>

                    <label htmlFor='titleEng'>English Title</label>
                    <input id='titleEng' type="text" value={titleEng} 
                           placeholder='English Title' required onChange={(e) => setTitleEng(e.target.value)}/>

                    <label htmlFor='textGeo'>ქართული ტექსტი</label>
                    <textarea id='textGeo' type="text" value={textGeo} 
                           placeholder='ქართული ტექსტი' required onChange={(e) => setTextGeo(e.target.value)}/>

                    <label htmlFor='textEng'>English Text</label>
                    <textarea id='textEng' type="text" value={textEng} 
                           placeholder='English Title' required onChange={(e) => setTextEng(e.target.value)}/>

                    <label htmlFor='image'>Image Url</label>
                    <input type="text" id='image' 
                           value={image} placeholder='Enter Image Url' 
                           onChange={e => setImage(e.target.value)}/>

                    <label htmlFor='image-file'>Upload Image</label>
                    <input type="file" id='image-file' onChange={handleUpload}/>
                     {uploading && <Loader/>}

                    <button type="submit">Update</button>
                </form>
            </div> }
        </div>
     <Footer/>
     </>
    )
}

export default AdminBlogEditScreen
