const translate = {
    GEO: {
      register: 'რეგისტრაცია',
      signIn: 'შესვლა',
      newCostumer: 'ახალი მომხმარებელი?',
      enterPassword: 'შეიყვანეთ პაროლი',
      _password: 'პაროლი',
      enterEmail: 'შეიყვანეთ მეილი',
      _email: 'მეილი',
      enterName: 'შეიყვანეთ სახელი',
      _name: 'სახელი',
      _confirmPassword: 'პაროლის დადასტურება',
      haveAccount: 'გაქვთ ანგარიში?',
      matchPasswrod: 'პაროლები არ ემთხვევა',
      profile: 'პროფილი',
      update: 'განახლება',
      profileUpdate: 'პროფილი წარმატებით განახლდა',
      review: 'კომენტარი',
      writeReview: 'დაწერეთ კომენტარი',
      submit: 'გამოაქვეყნე',
      back: 'უკან',
      search: 'ძებნა',
      searchTitle: 'მოძებნე ივენთი სახელით',
      noSearchResultFound: 'ივენთი არ მოიძებნა',
      please: 'გთხოვთ',
      autorization: 'გაიარეთ ავტორიზაცია',
      toWriteReview: 'კომენტარის დასაწერათ',
      home: 'მთავარი',
      about: 'ჩვენ შესახებ',
      blog: 'ბლოგი',
      events: 'ივენთები',
      contact: 'კონტაქტი',
      event: 'ივენთი',
      seeDetail: 'ნახე დეტალურად'
    },
    ENG : {
      register: 'Register',
      signIn: 'Sign In',
      newCostumer: 'New Costumer?',
      enterPassword: 'Enter Password',
      _password: 'Password',
      enterEmail: 'Enter Email',
      _email: 'Email Address',
      enterName: 'Enter Name',
      _name: 'Name',
      _confirmPassword: 'Confirm Password',
      haveAccount: 'Have an Account?',
      matchPasswrod: 'Passwords do not match',
      profile: 'Profile',
      update: 'Update',
      profileUpdate: 'Profile successfully updated',
      review: 'reviews',
      writeReview: 'Write Comment',
      submit: 'Submit',
      back: 'Back',
      search: 'search',
      searchTitle: 'search event by title',
      noSearchResultFound: 'No Search Result',
      please: 'Please',
      autorization: 'Sign In',
      toWriteReview: 'To Write A Review',
      home: 'Home',
      about: 'about us',
      blog: 'Blog',
      events: 'events',
      contact: 'contact',
      event: 'Event',
      seeDetail: 'See Details'
    }
}

export default translate