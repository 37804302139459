import React, { useEffect, useContext } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../components/Loader'
import { useHistory } from 'react-router-dom'
import { getSingleEvent} from '../actions/eventsActions'
import { LanguageContext } from '../context/languageContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faCalendar } from '@fortawesome/free-solid-svg-icons'
import '../styles/screens/singleEvent.scss'
import { EVENT_CREATE_REVIEW_RESET } from '../constants/eventsConstants'
import EventReview from '../components/EventReview'
import { REMOVE_REVIEW_RESET } from '../constants/reviewsConstants'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

function EventDetailsScreen({match}) {
 const eventId = match.params.id
 const { language } = useContext(LanguageContext)
 const dispatch = useDispatch()

 const singleEvent = useSelector(state => state.singleEvent)
 const { error, loading, event } = singleEvent

 const createEventReview = useSelector(state => state.createEventReview)
 const { success } = createEventReview

 const removeReview = useSelector(state => state.removeReview)
 const { success: removeSuccess} = removeReview

 useEffect(() => {
   if(!event || eventId !== event._id) {
     dispatch(getSingleEvent(eventId))
   }
   if(removeSuccess || success){
    dispatch({ type: EVENT_CREATE_REVIEW_RESET })
    dispatch({ type: REMOVE_REVIEW_RESET })
    dispatch(getSingleEvent(eventId))
   }
 }, [dispatch, eventId, success, removeSuccess, event])

const history = useHistory();
 const goToPreviousPath = () => {
    history.goBack()
}
 return (
     <>
      <NavBar singleEvent={true}/>
        <div className='single-event'>
            {error && error}
            {loading && <Loader/>} 
            {(event && event.titleEng) && <div className='single-event_container'>
            <img className='single-event_container-image' src={event.image} alt={event.titleEng}/>
              <div className={language === 'GEO' 
              ? 'single-event_container-small geoFont-Login' 
              : 'single-event_container-small'}>
               <FontAwesomeIcon className='single-event_container-small-back' icon={faArrowAltCircleLeft} onClick={goToPreviousPath}/>  
                <h2 className='single-event_container-small-title'>
                  {event && language === 'GEO' ? event.titleGeo : event.titleEng}
                </h2>
                  <p className='single-event_container-small-text'>
                      {language === 'GEO' ? event.textGeo : event.textEng}
                 </p>
                <p className='single-event_container-small-date'>
                  <FontAwesomeIcon icon={faCalendar}/>
                  {event.createdAt && event.createdAt.substring(0, 10)}
                </p>
               </div>
            </div>}
            <EventReview event={event} eventId={eventId}/>
        </div>
        <Footer/>
      </>
    )
}

export default EventDetailsScreen
