import React, {useContext, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Link} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faAngleDown, faBars, faRunning, faTimes, faUser } from '@fortawesome/free-solid-svg-icons'
import LanguageDropdown from './LanguageDropdown'
import translate from '../language/translate'
import {logout} from '../actions/userActions'
import {LanguageContext} from '../context/languageContext'
import useToggleState from '../hooks/useToggleState';
import '../styles/components/navbar.scss'

function NavBar({pathname, singleBlog, singleEvent}) {
 const { language } = useContext(LanguageContext)
 const { signIn, home, events, about, contact, blog } = translate[language]
 const [fixed, setFixed] = useState(false)
 const [isOpen, toggle] = useToggleState()
 const [isNavShow, setIsNavShow] = useState(false)
  
 const dispatch = useDispatch()
 const loginUser = useSelector(state => state.loginUser)
 const {user} = loginUser;
  
 const logoutHandler = () => {
    dispatch(logout())
 }

 const handleScroll = () => {
    let nav = document.getElementById('nav');
    let navHeight;
    if(nav){
      navHeight = nav.getBoundingClientRect().height
    } else {
      return
    }

    if(navHeight < window.pageYOffset){
        setFixed(true)
     } else {
        setFixed(false)
    }
 }

useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  document.body.addEventListener('click', closeNav)
  return () => {
    window.removeEventListener('scroll', handleScroll)
    document.body.removeEventListener('click', closeNav)
  }
})

const closeNav = e => {
  if(e.target.nodeName === 'BODY' || e.target.nodeName === 'SPAN'){
    setIsNavShow(false)
    document.body.classList.remove('navShowClass') 
  }
}

const handleNavigate = () => {
   let media = window.matchMedia("(max-width: 900px)")
   
  if(media.matches){
   setIsNavShow(!isNavShow)
    if(!isNavShow){
      document.body.classList.add('navShowClass')
      } else {
      document.body.classList.remove('navShowClass') 
    }
    document.body.addEventListener('click', closeNav)
 }
}

return (
    <div className={fixed  ? 'header stk-nav' : 'header'} 
         style={{backgroundColor: pathname !== '/' ? '#252525' : ''}}
      >
        <FontAwesomeIcon icon={faBars} onClick={handleNavigate} className='header-bar'/>
     <div className='header-logo'>
        <FontAwesomeIcon icon={faRunning}/>
     </div>
     <nav className={isNavShow ? 'header-nav navShow' : 'header-nav'} id='nav'>
           <ul className={language === 'GEO' ? 'geoFont-Nav header-nav-ul':'engFont-Nav header-nav-ul'} 
               onClick={() => window.scrollTo({top: 0})}>
               <li className='header-nav_homeLink' onClick={handleNavigate}>
                   <Link to='/' 
                       className={pathname === '/' || pathname === '/login' || pathname === '/register' ? 'active' : ''}
                     >
                     {home}
                    </Link>
                    <FontAwesomeIcon icon={faTimes} className='header-nav_closebtn'  />
               </li>
               <li onClick={handleNavigate}>
                   <Link to='/about' className={pathname === '/about' ? 'active' : ''} >
                     {about}
                   </Link>
               </li>
               <li onClick={handleNavigate}>
                   <Link  to='/blogs' className={pathname === '/blogs' || singleBlog ? 'active' : ''}>
                     {blog}
                   </Link>
               </li>
               <li onClick={handleNavigate}>
                   <Link  to='/events' className={pathname === '/events' || singleEvent ? 'active' : ''}>
                     {events}
                   </Link>
               </li>
               <li onClick={handleNavigate}>
                   <Link   to='/contact' className={pathname === '/contact' ? 'active' : ''}>
                     {contact}
                   </Link>
               </li>
           </ul>
           <div className='header-nav_dropdown'>
             <LanguageDropdown/>
           </div>
        </nav>
           <div className='header-nav-secondary'>
            <div className='header-nav-secondary_dropdown'>
      <LanguageDropdown/>
            </div>
            <div className="signIn-container">
                <div className='signIn-container-header'
                     onClick={toggle}>
                    {user ? <>
                             <span>{user.name}</span> 
                             <FontAwesomeIcon icon={faAngleDown} className={isOpen ? 'rotate-lft' : 'rotate-rght'}/>
                            </>
                    : <Link to='/login'> 
                        <FontAwesomeIcon icon={faUser} className='signIn-container-header-user'/>
                          <span className={language === 'GEO' ? 'geoFont-Login' : ''}>{signIn}</span> 
                       </Link>
                      }
                </div>
                {(user && isOpen) && 
                    <ul className={isOpen ? 'signIn-container-dropdown dropdown_animated_up' 
                         : 'signIn-container-dropdown'} onClick={toggle}>
                    {user && user.isAdmin && 
                    <><Link to='/userList'><li>Users</li></Link>
                    <Link to='/blogList'><li>Blogs</li></Link>
                    <Link to='/eventList'><li>Events</li></Link></>}
                    <li onClick={logoutHandler}>Logout</li>
                    <Link to='/user/profile'>
                      <li onClick={() => window.scrollTo({top: 0})}>Profile</li>
                    </Link>
                </ul>}
            </div>
          </div>
    </div>
  )
}

export default NavBar
