import React from 'react'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import '../styles/screens/contact.scss'


function ContactScreen({location}) {
  const {pathname} = location
return (
    <>
      <NavBar pathname={pathname}/>
        <div className='contact'>
           <div className='contact-minContainer'>
              <div className='contact-minContainer_line'></div>
              <h1>Contacts</h1>
           </div>
              <div className='contact-container'>
                 <div className='contact-container-getintouch'>
                    <ul className='contact-container-getintouch_items'>
                      <li className='contact-container-getintouch_items_list'>
                        <span className='contact-container-getintouch_items_list-mail'>E-Mail</span>
                        <p className='contact-container-getintouch_items_list-maildetail'>
                          <FontAwesomeIcon icon={faEnvelope}/>
                          <a href='mailto:metreveligia@gmail.com'>metreveligia@gmail.com</a>
                        </p>
                      </li>
                      <li className='contact-container-getintouch_items_list'>
                       <span className='contact-container-getintouch_items_list-address'>Address</span>
                        <p className='contact-container-getintouch_items_list-addressdetail'>
                          <FontAwesomeIcon icon={faMapMarkerAlt}/>
                          <span>Rustavi, Kostava Evenue 7 </span>
                        </p>
                      </li>
                      <li className='contact-container-getintouch_items_list'>
                       <span className='contact-container-getintouch_items_list-phone'>Phone</span>
                         <p className='contact-container-getintouch_items_list-phonedetail'>
                          <FontAwesomeIcon icon={faPhoneAlt}/>
                          <a href='tel:599999999'>
                            599 99 99 99
                          </a>
                        </p>
                      </li>
                    </ul>
                 </div>
                 <div className='contact-container-form'>
                    <form>
                       <div className='contact-container-form-input'>
                        <input type='text' name='name' placeholder='your name' required/>
                        <input type='text' name='email' placeholder='your email' required/>
                        <input type='text' name='phone' placeholder='your phone' required/>
                       </div>
                       <textarea type='text' name='message' placeholder='message' required/>
                       <button type='submit'>Send Message</button>
                    </form>
                 </div>
            </div>
            <div className="contact-google_map">
                  <iframe
                  title='location'
                  src="https://maps.google.com/maps?q=Tbilisi&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  allowFullScreen>
                  </iframe>
            </div>
        </div>
      <Footer/>
    </>
  )
}

export default ContactScreen
