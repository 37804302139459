import { 
    USER_ADMIN_UPDATE_FAIL,
    USER_ADMIN_UPDATE_REQUEST,
    USER_ADMIN_UPDATE_RESET,
    USER_ADMIN_UPDATE_SUCCESS,
    USER_LIST_FAIL, 
    USER_LIST_REQUEST, 
    USER_LIST_SUCCESS, 
    USER_LOGIN_FAIL, 
    USER_LOGIN_REQUEST, 
    USER_LOGIN_SUCCESS, 
    USER_LOGOUT, 
    USER_PROFILE_FAIL, 
    USER_PROFILE_REQUEST, 
    USER_PROFILE_RESET, 
    USER_PROFILE_SUCCESS, 
    USER_REGISTER_FAIL, 
    USER_REGISTER_REQUEST, 
    USER_REGISTER_SUCCESS, 
    USER_REMOVE_FAIL, 
    USER_REMOVE_REQUEST,
    USER_REMOVE_SUCCESS,
    USER_UPDATE_FAIL,
    USER_UPDATE_REQUEST,
    USER_UPDATE_RESET,
    USER_UPDATE_SUCCESS
} from "../constants/userConstants";

export const loginUserReducer = (state = {}, action) => {
    switch(action.type){
        case USER_LOGIN_REQUEST:
            return {
                loading: true,
            }
        case USER_LOGIN_SUCCESS :
            return {
                loading: false,
                user: action.payload
            }
        case USER_LOGIN_FAIL :
            return {
                loading: false,
                error: action.payload
            }
        case USER_LOGOUT:
            return {}
        default: 
            return state
    }
}

export const newUserRegisterReducer = (state = {}, action) => {
    switch(action.type){
        case USER_REGISTER_REQUEST:
            return {
                loading: true,
            }
        case USER_REGISTER_SUCCESS :
            return {
                loading: false,
                newUser: action.payload
            }
        case USER_REGISTER_FAIL :
            return {
                loading: false,
                error: action.payload
            }
        default: 
            return state
    }
}

export const userListReducer = (state = { users: []}, action) => {
    switch(action.type){
        case USER_LIST_REQUEST:
            return {
                loading: true,
                users :[]
            }
        case USER_LIST_SUCCESS :
            return {
                loading: false,
                users: action.payload
            }
        case USER_LIST_FAIL :
            return {
                loading: false,
                error: action.payload
            }
        default: 
            return state
    }
}

export const userDetailReducer = (state = { userInfo : {}}, action) => {
  switch(action.type){
      case USER_PROFILE_REQUEST :
          return {
              loading: true,
              ...state
          }
      case USER_PROFILE_SUCCESS :
          return {
              loading: false,
              userInfo: action.payload
          }
      case USER_PROFILE_RESET :
          return {}
      case USER_PROFILE_FAIL :
          return {
              loading: false,
              error: action.payload
          }
      default: 
          return state
  }
}

export const userUpdateReducer = (state = {}, action) => {
    switch(action.type){
        case USER_UPDATE_REQUEST : 
          return {
              loading: true
          }
        case USER_UPDATE_SUCCESS :
          return {
              loading: false, success: true, updatedUser: action.payload
          }
        case USER_UPDATE_RESET:
            return {
                success: false
            }
        case USER_UPDATE_FAIL :
          return {
              loading: false, error: action.payload
          }
        default:
            return state
    }
}


export const userRemoveReducer = (state = {}, action) => {
    switch(action.type){
        case USER_REMOVE_REQUEST : 
          return {
              loading: true,
          }
        case USER_REMOVE_SUCCESS :
          return {
              loading: false,
              success: true,
          }
        case USER_REMOVE_FAIL :
          return {
              loading: false, error: action.payload
          }
        default:
            return state
    }
}

export const userAdminUpdateReducer = (state = {}, action) => {
    switch(action.type){
        case USER_ADMIN_UPDATE_REQUEST : 
          return {
              loading: true
          }
        case USER_ADMIN_UPDATE_SUCCESS :
          return {
              loading: false, success: true, updatedUser: action.payload
          }
        case USER_ADMIN_UPDATE_RESET:
            return {}
        case USER_ADMIN_UPDATE_FAIL :
          return {
              loading: false, error: action.payload
          }
        default:
            return state
    }
}