export const EVENT_LIST_REQUEST = 'EVENT_LIST_REQUEST';
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS';
export const EVENT_LIST_FAIL = 'EVENT_LIST_FAIL';

export const EVENT_DETAIL_REQUEST = 'EVENT_DETAIL_REQUEST';
export const EVENT_DETAIL_SUCCESS = 'EVENT_DETAIL_SUCCESS';
export const EVENT_DETAIL_FAIL = 'EVENT_DETAIL_FAIL';
export const EVENT_DETAIL_RESET = 'EVENT_DETAIL_RESET';

export const EVENT_REMOVE_REQUEST = 'EVENT_REMOVE_REQUEST';
export const EVENT_REMOVE_SUCCESS = 'EVENT_REMOVE_SUCCESS';
export const EVENT_REMOVE_FAIL = 'EVENT_REMOVE_FAIL';

export const EVENT_CREATE_REQUEST = 'EVENT_CREATE_REQUEST';
export const EVENT_CREATE_SUCCESS = 'EVENT_CREATE_SUCCESS';
export const EVENT_CREATE_FAIL = 'EVENT_CREATE_FAIL';
export const EVENT_CREATE_RESET = 'EVENT_CREATE_RESET';

export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST';
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS';
export const EVENT_UPDATE_FAIL = 'EVENT_UPDATE_FAIL';
export const EVENT_UPDATE_RESET = 'EVENT_UPDATE_RESET';

export const EVENT_CREATE_REVIEW_REQUEST = 'EVENT_CREATE_REVIEW_REQUEST';
export const EVENT_CREATE_REVIEW_SUCCESS = 'EVENT_CREATE_REVIEW_SUCCESS';
export const EVENT_CREATE_REVIEW_FAIL = 'EVENT_CREATE_REVIEW_FAIL';
export const EVENT_CREATE_REVIEW_RESET = 'EVENT_CREATE_REVIEW_RESET';



