import slideOne from '../staticImages/slideOne.webp'
import slideTwo from '../staticImages/slideTwo.webp'
import slideThree from '../staticImages/slideThree.webp'

export const slider = [
  {
      image: slideOne,
      id: 1,
      titleGeo: 'ტექსტი ქართული',
      textGeo: 'Obstacle Sports Georgia',
      buttonTextGeo: 'მეტის ნახვა',
      titleEng: 'English Text',
      textEng: 'Obstacle Sports Georgia',
      buttonTextEng: 'Read More'
 },
 {
    image: slideTwo,
    id: 2,
    titleGeo: 'ტექსტი ქართული',
    textGeo: 'Obstacle-Sports',
    buttonTextGeo: 'მეტის ნახვა',
    titleEng: 'English text',
    textEng: 'Obstacle-Sports',
    buttonTextEng: 'Read more'
},
{
    image: slideThree,
    id: 3,
    titleGeo: 'ქართული ტექსტი',
    textGeo: 'ყველაზე მოთხოვნადი',
    buttonTextGeo: 'მეტის ნახვა',
    titleEng: 'English Title',
    textEng: 'Top Rated Events',
    buttonTextEng: 'Read More'
},
]