import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom'
import '../styles/components/footer.scss'
import '../styles/components/scroll.scss'
import ScrollUpButton from './ScrollUpButton'
import translate from '../language/translate'
import { LanguageContext } from '../context/languageContext'


function Footer() {
 const [isDown, setIsDown] = useState(false)
 const [date] = useState(new Date().getFullYear())
 const {language} = useContext(LanguageContext)
 const { home, events, about, contact, blog } = translate[language]
 const handleScroll = () => {
 const footer = document.getElementById('footer')
    if(footer.offsetTop <= window.scrollY + 800){
       setIsDown(true)
    } else {
        setIsDown(false)
    }
 }
 useEffect(() => {
   window.addEventListener('scroll', handleScroll);
 
   return () => {
     window.removeEventListener('scroll', handleScroll)
   }
 })
    return (
     <footer className='footer' id='footer'>
            <div className='footer-container'>
               <ul className={language === 'GEO' ? 'geoFont-Nav footer-container-list':'engFont-Nav footer-container-list'} 
                   onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                  <li>
                     <Link to='/'> {home} </Link>
                  </li>
                  <li>
                     <Link to='/about'>{about}</Link>
                  </li>
                  <li>
                     <Link to='/blogs'>{blog}</Link>
                  </li>
                  <li>
                     <Link to='/events'>{events}</Link>
                  </li>
                  <li>
                     <Link to='/contact'>{contact}</Link>
                  </li>
               </ul>
               <div className='footer-container-socials'>
                      <a className='footer-container-socials-link' href='https://www.facebook.com/' target='_blank' rel='noreferrer'>
                         <FontAwesomeIcon icon={faFacebook}/>
                      </a>
                      <a className='footer-container-socials-link' href='https://linkedin.com/' target='_blank' rel='noreferrer'>
                        <FontAwesomeIcon icon={faLinkedin}/>
                      </a>
            
                      <a className='footer-container-socials-link' href='https://www.instagram.com/' target='_blank' rel='noreferrer'>
                        <FontAwesomeIcon icon={faInstagram}/>
                      </a>
               </div>
               <p className='footer-container-socials-text'>
                  Copyright © {date} All Rightes Reserved | Obstacle Sports Georgia
               </p>
               <p className='footer-container-socials-shmagi'>
                  This Website Is Made With Love By 
                  <a href='https://shmagi-portfolio.web.app/' target="_blank" rel="noreferrer">
                     Shmagi
                  </a>
               </p>
            </div>
         {isDown && <ScrollUpButton/>}
      </footer>
    )
}

export default Footer
