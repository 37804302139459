import React, {useContext} from 'react'
import { LanguageContext } from '../context/languageContext'
import '../styles/components/obstacleGeo.scss'
import { obstacleGeo } from '../helpers/staticContent';

function ObstacleGeorgia() {
  const { language } = useContext(LanguageContext)

    return (
        <section id="obstacle-sports-georgia" className='obstacle-sports-georgia'>
        <div className='obstacle-sports-georgia_left'>
             <div className='obstacle-sports-georgia_left-line'></div>
             <h1><span></span>Obstacle Sports Georgia</h1>
             <p>
                {language === 'GEO' ? obstacleGeo.textGeoFirst :obstacleGeo.textEngFirst}
              </p>
              <p>
                {language === 'GEO' ? obstacleGeo.textGeoSecond :obstacleGeo.textEngSecond}
             </p>
        </div>
        <div className='obstacle-sports-georgia_right'>
           <img src={obstacleGeo.image} alt='obstacle-sport-georgia' className='obstacle-sports-georgia_right-img'/>
        </div>
  </section>
    )
}

export default ObstacleGeorgia
