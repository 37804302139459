import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import '../styles/components/loader.scss';

function Loader() {
    return (
        <div className='loader'>
         <FontAwesomeIcon icon={faSpinner} className='loader-spinner'/>
        </div>
    )
}

export default Loader
