import React, {useState, useEffect} from 'react'
import Alert from '../components/Alert'
import Loader from '../components/Loader'
import {useDispatch, useSelector} from 'react-redux'
import { createBlogAction } from '../actions/blogsActions'
import { BLOG_CREATE_RESET } from '../constants/blogsConstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { uploadImage } from '../helpers/upload'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'


function AdminCreateBlogScreen({history}) {
    const [titleGeo, setTitleGeo] = useState('')
    const [titleEng, setTitleEng] = useState('')
    const [textGeo, setTextGeo] = useState('')
    const [textEng, setTextEng] = useState('')
    const [image, setImage] = useState('')
    const [uploading, setUploading] = useState(false)
    const dispatch = useDispatch()
    const loginUser = useSelector(state => state.loginUser)
    const { user } = loginUser

    const createBlog = useSelector(state => state.createBlog)
    const { error, success, loading } = createBlog

    useEffect(() => {
        if(user && user.isAdmin){
             if(success){
                 history.push('/blogList')
                 dispatch({ type: BLOG_CREATE_RESET})
             }
        } else {
           history.push('/')
        }
    }, [history, user, success, dispatch])

    const handleSubmit = e => {
        e.preventDefault()
        dispatch(createBlogAction(
            titleEng,
            titleGeo,
            textEng,
            textGeo,
            image
        ))
    }

    const handleUpload = async(e) => {
       const file = e.target.files[0]
       const formData = new FormData()
       formData.append('image', file)
       uploadImage(formData, setImage, setUploading)
    }
    return (
      <>
      <NavBar/>
        <div className='form_blog'>
            <h1>Create New Blog</h1>
            {error && <Alert text={error} success={false}/>}
            {loading && <Loader/>}
            <div className='form_blog-container'>
                <Link to='/blogList' className='form_blog-container-back'>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </Link>
                <form onSubmit={handleSubmit} className="form_blog-container-form">

                    <label htmlFor='titleGeo'>ქართული სათაური</label>
                    <input id='titleGeo' type="text" value={titleGeo} 
                           placeholder='ქართული სათაური' required onChange={(e) => setTitleGeo(e.target.value)}/>

                    <label htmlFor='titleEng'>English Title</label>
                    <input id='titleEng' type="text" value={titleEng} 
                           placeholder='English Title' required onChange={(e) => setTitleEng(e.target.value)}/>

                    <label htmlFor='textGeo'>ქართული ტექსტი</label>
                    <textarea id='textGeo' type="text" value={textGeo} 
                           placeholder='ქართული ტექსტი' required onChange={(e) => setTextGeo(e.target.value)}/>

                    <label htmlFor='textEng'>English Text</label>
                    <textarea id='textEng' type="text" value={textEng} 
                           placeholder='English Title' required onChange={(e) => setTextEng(e.target.value)}/>

                    <label htmlFor='image'>Image Url</label>
                    <input type="text" id='image' 
                           value={image} placeholder='Enter Image Url' 
                           onChange={e => setImage(e.target.value)}/>

                    <label htmlFor='image-file'>Upload Image</label>
                    <input type="file" id='image-file' onChange={handleUpload}/>
                     {uploading && <Loader/>}
                    <button type="submit">Create</button>
                </form>
            </div>
        </div>
      <Footer/>
     </>
    )
}

export default AdminCreateBlogScreen
