import { 
    REMOVE_REVIEW_FAIL, 
    REMOVE_REVIEW_REQUEST, 
    REMOVE_REVIEW_RESET, 
    REMOVE_REVIEW_SUCCESS,
    UPDATE_REVIEW_FAIL,
    UPDATE_REVIEW_REQUEST,
    UPDATE_REVIEW_RESET,
    UPDATE_REVIEW_SUCCESS
} from '../constants/reviewsConstants'

export const removeReviewReducer = ( state = {}, action) => {
    switch(action.type){
        case REMOVE_REVIEW_REQUEST : 
          return {
              loading: true,
          }
        case REMOVE_REVIEW_SUCCESS : 
          return {
              loading: false,
              success: true,
          }
        case REMOVE_REVIEW_FAIL:
          return {
              loading: false,
              error: action.payload
          }
        case REMOVE_REVIEW_RESET:
          return { }
        default :
           return state
    }
}

export const updateReviewReducer = ( state = {}, action) => {
  switch(action.type){
      case UPDATE_REVIEW_REQUEST : 
        return {
            loading: true,
        }
      case UPDATE_REVIEW_SUCCESS : 
        return {
            loading: false,
            success: true,
        }
      case UPDATE_REVIEW_FAIL:
        return {
            loading: false,
            error: action.payload
        }
      case UPDATE_REVIEW_RESET:
        return { }
      default :
         return state
  }
}