import { 
  USER_ADMIN_UPDATE_FAIL,
  USER_ADMIN_UPDATE_REQUEST,
  USER_ADMIN_UPDATE_SUCCESS,
  USER_LIST_FAIL, 
  USER_LIST_REQUEST, 
  USER_LIST_SUCCESS, 
  USER_LOGIN_FAIL, 
  USER_LOGIN_REQUEST, 
  USER_LOGIN_SUCCESS, 
  USER_LOGOUT, 
  USER_PROFILE_FAIL, 
  USER_PROFILE_REQUEST, 
  USER_PROFILE_RESET, 
  USER_PROFILE_SUCCESS, 
  USER_REGISTER_FAIL, 
  USER_REGISTER_REQUEST, 
  USER_REGISTER_SUCCESS, 
  USER_REMOVE_FAIL,
  USER_REMOVE_REQUEST,
  USER_REMOVE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS
} from "../constants/userConstants"
import axios from 'axios';


export const loginUserActon = (email, password) => async(dispatch) => {
  try {
     dispatch({
         type: USER_LOGIN_REQUEST,
     })

     const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

     const { data } = await axios.post('/api/users/login', {email, password}, config)

     dispatch({
         type: USER_LOGIN_SUCCESS,
         payload: data
     })

     window.localStorage.setItem('user', JSON.stringify(data))
  }
  catch(error){
     dispatch({
         type: USER_LOGIN_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message
     })
  }
}

export const logout = () => (dispatch) => {
  window.localStorage.removeItem('user')
  dispatch({ type: USER_LOGOUT })
  dispatch({ type: USER_PROFILE_RESET })
  document.location.href = '/login'
}

export const registerNewUserActon = (name, email, password) => async(dispatch) => {
  try {
     dispatch({
         type: USER_REGISTER_REQUEST,
     })

     const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }

     const { data } = await axios.post('/api/users', {name, email, password}, config)

     dispatch({
         type: USER_REGISTER_SUCCESS,
         payload: data
     })

     dispatch({
       type: USER_LOGIN_SUCCESS,
       payload: data
     })

     window.localStorage.setItem('user', JSON.stringify(data))
  }
  catch(error){
     dispatch({
         type: USER_REGISTER_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message
     })
  }
}

export const userListAction = () => async(dispatch, getState) => {
  try{
    dispatch({
      type: USER_LIST_REQUEST
    })

    const { loginUser: { user } } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }

    const { data } = await axios.get('/api/users', config)

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data
    })
  
  }
  catch(error){
    dispatch({
      type: USER_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const userDetailAction = (id) => async(dispatch, getState) => {
  try{
    dispatch({
      type: USER_PROFILE_REQUEST
    })

    const { loginUser: { user } } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }

      const { data } = await axios.get(`/api/users/${id}`, config)

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data
    })
  
  }
  catch(error){
    dispatch({
      type: USER_PROFILE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}


export const userUpdateAction = (updateUser) => async(dispatch, getState) => {
  try {
    dispatch({
        type: USER_UPDATE_REQUEST
    })

    const { loginUser : { user } } = getState()

    const config = {
        headers : {
         'Content-Type' : 'application/json',
          Authorization: `Bearer ${user.token}`
        }
    }

    const { data } = await axios.put(`/api/users/profile`, updateUser, config)

    dispatch({
        type: USER_UPDATE_SUCCESS,
        payload: data
    })

    dispatch({
     type: USER_LOGIN_SUCCESS,
     payload: data
 })

    window.localStorage.setItem('user', JSON.stringify(data))

 } catch(error){
    dispatch({
        type: USER_UPDATE_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
 }
}


export const userAdminUpdateAction = (updateUser) => async(dispatch, getState) => {
  try {
    dispatch({
        type: USER_ADMIN_UPDATE_REQUEST
    })

    const { loginUser : { user } } = getState()

    const config = {
        headers : {
         'Content-Type' : 'application/json',
          Authorization: `Bearer ${user.token}`
        }
    }

    const { data } = await axios.put(`/api/users/${updateUser._id}`, updateUser, config)

    dispatch({
        type: USER_ADMIN_UPDATE_SUCCESS,
        payload: data
    })

    dispatch({
      type: USER_PROFILE_SUCCESS,
      payload: data
  })

 } catch(error){
    dispatch({
        type: USER_ADMIN_UPDATE_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
 }
}



export const removeUser = (id) => async(dispatch, getState) => {
  try {
     dispatch({
         type: USER_REMOVE_REQUEST
     })

     const { loginUser : { user } } = getState()

     const config = {
         headers : {
           Authorization: `Bearer ${user.token}`
         }
     }

     await axios.delete(`/api/users/${id}`, config)

     dispatch({ type: USER_REMOVE_SUCCESS })

  } catch(error){
     dispatch({
         type: USER_REMOVE_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message
     })
  }
}
