import React from 'react'
import { BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import AboutScreen from './screens/AboutScreen';
import BlogScreen from './screens/BlogScreen';
import HomeScreen from './screens/HomeScreen'
import ObstacleGeorgiaScreen from './screens/ObstacleGeorgiaScreen';
import EventsScreen from './screens/EventsScreen'
import ContactScreen from './screens/ContactScreen'
import { LanguageProvider } from './context/languageContext'
import EventDetailsScreen from './screens/EventDetailsScreen';
import BlogDetailScreen from './screens/BlogDetailScreen';
import UserLoginScreen from './screens/UserLoginScreen';
import RegisterUserScreen from './screens/RegisterUserScreen';
import UserListScreen from './screens/UserListScreen';
import UserDetailScreen from './screens/UserDetailScreen';
import UserEditScreen from './screens/UserEditScreen';
import AdminBlogsListScreen from './screens/AdminBlogsListScreen';
import AdminCreateBlogScreen from './screens/AdminCreateBlogScreen';
import AdminBlogEditScreen from './screens/AdminBlogEditScreen';
import AdminEventListScreen from './screens/AdminEventListScreen';
import AdminCreateEventScreen from './screens/AdminCreateEventScreen';
import AdminEventEditScreen from './screens/AdminEventEditScreen';

// mongodb+srv://shmagi9017:shmagi9017@obstacle-sports.f6dsx.mongodb.net/obstacle-sports

function App() {
  return (
    <LanguageProvider>
      <Router>
          <Switch>
            <Route path='/' component={HomeScreen} exact/>
            <Route path='/about' component={AboutScreen} exact/>
            <Route path='/obstacle-georgia' component={ObstacleGeorgiaScreen} exact/>
            <Route path='/blogs' component={BlogScreen} exact/>
            <Route path='/blogList' component={AdminBlogsListScreen} exact/>
            <Route path='/blogs/:id' component={BlogDetailScreen} exact/>
            <Route path='/blogs/:id/edit' component={AdminBlogEditScreen} exact/>
            <Route path='/blog/create' component={AdminCreateBlogScreen} exact/>
            <Route path='/events' component={EventsScreen} exact/>
            <Route path='/search/:keyword' component={EventsScreen}/>
            <Route path='/events/:id' component={EventDetailsScreen} exact/>
            <Route path='/event/create' component={AdminCreateEventScreen} exact/>
            <Route path='/events/:id/edit' component={AdminEventEditScreen} exact/>
            <Route path='/eventList' component={AdminEventListScreen} exact/>
            <Route path='/contact' component={ContactScreen} exact/>
            <Route path='/login' component={UserLoginScreen} exact/>
            <Route path='/register' component={RegisterUserScreen} exact/>
            <Route path='/userList' component={UserListScreen} exact/>
            <Route path='/user/profile' component={UserDetailScreen} exact/>
            <Route path='/user/profile/:id/edit' component={UserEditScreen} exact/>
            <Redirect to='/'/>
          </Switch>
        </Router>
     </LanguageProvider>
  );
}

export default App;
