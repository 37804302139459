import React from 'react'

function ObstacleGeorgiaScreen() {
    return (
        <div className='py-5'>
            <h1>This is Obstacle Georgia Screen</h1>
        </div>
    )
}

export default ObstacleGeorgiaScreen
