import React, {useContext} from 'react'
import '../styles/screens/home.scss'
import SwiperCore, { Pagination, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { slider } from '../helpers/slider'
import { LanguageContext } from '../context/languageContext'
import '../styles/abstracts/_homeswiper.scss'
import '../styles/abstracts/_homeswiperpag.scss'
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ObstacleGeorgia from '../components/ObstacleGeorgia';
import ObstacleSports from '../components/ObstacleSports';


SwiperCore.use([Pagination, Autoplay, A11y]);

function HomeScreen({location}) {
 const {pathname} = location
 const { language } = useContext(LanguageContext)
    return (
        <>
        <NavBar pathname={pathname}/>
        <div className="home">
            <div className='home-slider-container'>
                <Swiper
                    slidesPerView={1}
                    autoplay={{delay: 5000 }}
                    pagination={{ clickable: true }}
                    >
                {slider.map((slide, index) => (
                    <SwiperSlide key={slide.id} style={{background : `linear-gradient(rgba(10, 10, 10, 0.3), rgba(0, 0, 0, 0.6)),
                    url(${slide.image}) center/cover no-repeat`}}>
                        {({isActive}) => (
                          <div className={isActive ? 'swiper-slide-container animated' : 'swiper-slider-container'}>
                              <span></span>
                          <h2 className={
                               language === 'GEO' ? 'swiper-slide-container_title geoFont-Login' 
                               : 'swiper-slide-container_title'}>
                              {language === 'GEO' ? slide.titleGeo : slide.titleEng}
                          </h2>
                          <h1 className={
                              language === 'GEO' ? 'swiper-slide-container_text geoFont-Login' 
                              : 'swiper-slide-container_text'}>
                              {language === 'GEO' ? slide.textGeo : slide.textEng}
                          </h1>
                          <a 
                              href={index === 0 ? '#obstacle-sports-georgia' 
                              : index === 1 ? '#obstacle-sports' 
                              : '#top-rated'} 
                              className={language === 'GEO' 
                                ? 'swiper-slide-container_btn geoFont-Login' 
                               : 'swiper-slide-container_btn'}>
                               {language === 'GEO' ? slide.buttonTextGeo : slide.buttonTextEng}
                          </a>
                      </div> )}
                    </SwiperSlide>
                ))}
                </Swiper>
                <ObstacleGeorgia/>
                <ObstacleSports/>
              <Footer/>
            </div>
        </div>
      </>
    )
}

export default HomeScreen
