import React, {useEffect, useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { removeReviewAction, updateReviewAction } from '../actions/reviewAction'
import { useDispatch, useSelector } from 'react-redux'
import useToggleState from '../hooks/useToggleState'


function ReviewEditForm({review, index, event, eventId}) {
const [selectedIndex, setSelectedIndex] = useState(null)
const [singleReview, setSingleReview] = useState(null)
const [comment, setComment] = useState('')
const [isEditing, toggle] = useToggleState()

const dispatch = useDispatch()
   
const loginUser  = useSelector(state => state.loginUser)
const { user } = loginUser

const removeReview = useSelector(state => state.removeReview)
const { success: removeSuccess} = removeReview

const updateReview = useSelector(state => state.updateReview)
const { success: updateSuccess} = updateReview

useEffect(() => {
    if(singleReview){
        setComment(singleReview)
    } else {
        setComment('')
 }
}, [singleReview, removeSuccess, updateSuccess])

const handleRemove = (evntId, reviewId) => {
    dispatch(removeReviewAction(evntId, reviewId))
}

const findeSingleReview = (reviewId, indx) => {
    setSelectedIndex(indx)
     toggle()
    let rvw;
     rvw = event.reviews.filter(rvw => rvw._id === reviewId)
    setSingleReview(rvw[0].comment)
}
    
const handleUpdate = (eventId, reviewId, comment) => {
    toggle()
    setSingleReview(null)
    dispatch(updateReviewAction(eventId, reviewId, {
            comment
    }))
}
    return (
        <div className='event_reviews-list-items' key={review._id}>
        {(user && user.isAdmin) && 
        <FontAwesomeIcon icon={faTrash} onClick={() => handleRemove(eventId, review._id)}/>}

        {user && user._id === review.user && 
         <><FontAwesomeIcon icon={faEdit} onClick={() => findeSingleReview(review._id, index)}/> 
         <FontAwesomeIcon icon={faTrash} onClick={() => handleRemove(eventId, review._id)}/> </>}

        <p className='event_reviews-list-items_user'>
            <strong>{review.name}</strong>
        <span className='event_reviews-list-items_date'>
            {review.createdAt.substring(0, 10)}
        </span>
        </p>
        
        {((index === selectedIndex) && isEditing && user && user._id === review.user) ? 
        <div className='review-edit'>
           <input 
            style={{padding: '.5rem'}} type='text' 
            onChange={(e) => setComment(e.target.value)} 
            value={comment}/> 
           <button className='review-edit_btn' onClick={toggle}>Cancel</button>
           <button className='review-edit_btn' onClick={() => handleUpdate(eventId, review._id, comment)}>Edit</button>
         </div>
        : <p className='event_reviews-list-items-comment'>
            {review.comment}
          </p>}
     </div>
  )
}

export default ReviewEditForm
