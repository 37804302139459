import React, {useState, useEffect, useContext} from 'react'
import {LanguageContext} from '../context/languageContext'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import Loader from '../components/Loader'
import Alert from '../components/Alert'
import {getEventsList} from '../actions/eventsActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { removeEventAction } from '../actions/eventsActions'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

function AdminEventListScreen({history}) {
    const {language} = useContext(LanguageContext)
    const dispatch = useDispatch()
    const [eventTitle, setEventTitle] = useState('')
    const [eventFound, setEventFound] = useState([])
    const [message, setMessage] = useState(null)
  
    const loginUser = useSelector(state => state.loginUser)
    const { user } = loginUser

    const eventsList = useSelector(state => state.eventsList)
    const { error, loading, events} = eventsList
  
    const removeEvent = useSelector(state => state.removeEvent)
    const { error:removeError, loading: removeLoading, success} = removeEvent

    useEffect(() => {
        if(user && user.isAdmin) {
            dispatch(getEventsList())
          } else {
            history.push('/')
        }
    }, [user, history, dispatch, success])
  
    const handleSubmit = e => {
      e.preventDefault();
     let filteredEvent;
     filteredEvent = events.filter(
         event => event.titleGeo.toLowerCase() === eventTitle.toLowerCase() ||
         event.titleEng.toLowerCase() === eventTitle.toLowerCase()
        )
     if(filteredEvent.length){
         setEventFound(filteredEvent)
         setMessage(null)
     } else {
         setEventFound([])
         setMessage('Event Not Found')
     }
     setEventTitle('')
    }
  
    const deleteHandler = id => {
        dispatch(removeEventAction(id))
        setEventFound([])
    }
  
      return (
          <>
          <NavBar/>
          <div className='event_List'>
              <h2>Event List</h2>
              {(error || removeError) && <Alert text={error ? error : removeError} success={false}/>}
              {loading || removeLoading ? <Loader/> 
              :<div className='event_List-container'>
                  <div className='event_List-container-createBtn'>
                      <Link to='/event/create'>
                         <FontAwesomeIcon icon={faPlus}/> Create Event
                      </Link>
                  </div>
                  <table className='event_List-container-table'>
                 <thead>
                   <tr>
                      <th>TITLE</th>
                      <th>IMAGE</th>
                      <th>TEXT</th>
                      <th>REVIEWS</th>
                      <th>CREATED</th>
                      <th></th>
                   </tr>
                  </thead>
                  <tbody>
                 {events.map(event => (
                     <tr key={event._id}>
                         <td>
                         <Link to={`/events/${event._id}`}> 
                           {language === 'GEO' ? event.titleGeo : event.titleEng} 
                         </Link>
                        </td>
                         <td>
                           <img src={event.image} alt={event.titleEng} style={{width: '5rem'}}/>
                          </td>
                         <td>
                            {language === 'GEO'  ? 
                            `${event.textGeo.substring(0, 90)}...`
                            : event.textEng.substring(0, 90)}...
                         </td>
                         <td>
                             {event.reviews.length}
                         </td>
                         <td>
                            {event.createdAt.substring(0, 10)}
                         </td>
                         <td>
                           <Link to={`/events/${event._id}/edit`}><FontAwesomeIcon icon={faEdit}/></Link>
                           <FontAwesomeIcon 
                            icon={faTrash} 
                            onClick={() => deleteHandler(event._id)}
                            style={{cursor: 'pointer', marginLeft: '1rem'}}
                            />
                         </td>
                     </tr>
                 ))}
                 </tbody>
                </table>
               </div>
              }
              <div className='event_List-find'>
                  <h2>Find Event By Title</h2>
                  <form onSubmit={handleSubmit}>
                      <input 
                      type='text' 
                      value={eventTitle} 
                      onChange={e => setEventTitle(e.target.value)} 
                      placeholder='search event by title'/>
                      <button type='submit'>Search</button>
                  </form>
                  {message && <Alert text={message} success={false}/>}
                  {eventFound.length > 0 && 
                  <>
                   <FontAwesomeIcon icon={faTimes} onClick={() => setEventFound([])} style={{cursor: 'pointer', fontSize: '1.2rem'}}/>
                  <table className='event_List-container-table'>
                  <thead>
                   <tr>
                      <th>TITLE</th>
                      <th>IMAGE</th>
                      <th>TEXT</th>
                      <th>REVIEWS</th>
                      <th>CREATED</th>
                      <th></th>
                   </tr>
                   </thead>
                   <tbody>
                 {eventFound.map(event => (
                     <tr key={event._id}>
                         <td>
                         <Link to={`/events/${event._id}`}> 
                           {language === 'GEO' ? event.titleGeo : event.titleEng} 
                         </Link>
                         </td>
                         <td>
                          <img src={event.image} alt={event.titleEng} style={{width: '5rem'}}/>
                         </td>
                         <td>
                           {language === 'GEO'  ? event.textGeo : event.textEng}
                          </td>
                          <td>
                             {event.reviews.length}
                          </td>
                          <th>
                              {event.createdAt.substring(0, 10)}
                          </th>
                         <td>
                          <Link to={`/events/${event._id}/edit`}><FontAwesomeIcon icon={faEdit}/></Link>
                           <FontAwesomeIcon 
                            icon={faTrash} 
                            onClick={() => deleteHandler(event._id)}
                            style={{cursor: 'pointer', marginLeft: '1rem'}}
                            />
                         </td>
                     </tr>
                 ))}
                 </tbody>
                </table></>}
              </div>
          </div>
         <Footer/>
        </>
    )
}

export default AdminEventListScreen
