import { 
    REMOVE_REVIEW_FAIL, 
    REMOVE_REVIEW_REQUEST, 
    REMOVE_REVIEW_SUCCESS
} from '../constants/reviewsConstants'
import axios from 'axios'

export const removeReviewAction = (eventId, reviewId) => async(dispatch, getState) => {
    try {
      dispatch({
          type: REMOVE_REVIEW_REQUEST
      })

      const { loginUser: { user }} = getState()

      const config = {
        headers : {
          Authorization: `Bearer ${user.token}`
        }
      }
 
      await axios.delete(`/api/events/${eventId}/reviews/${reviewId}`, config)
 
      dispatch({type: REMOVE_REVIEW_SUCCESS })
    }
    catch(error){
     dispatch({
         type: REMOVE_REVIEW_FAIL,
         payload: error.response && error.response.data.message ? error.response.data.message : error.message
     })
    }
}

export const updateReviewAction = (eventId, reviewId, comment) => async(dispatch, getState) => {
  try {
    dispatch({
        type: REMOVE_REVIEW_REQUEST
    })

    const { loginUser: { user }} = getState()

    const config = {
      headers : {
        Authorization: `Bearer ${user.token}`
      }
    }

    await axios.put(`/api/events/${eventId}/reviews/${reviewId}`, comment, config)

    dispatch({type: REMOVE_REVIEW_SUCCESS })
  }
  catch(error){
   dispatch({
       type: REMOVE_REVIEW_FAIL,
       payload: error.response && error.response.data.message ? error.response.data.message : error.message
   })
  }
}