import axios from 'axios'

const uploadImage = async (formData, func, uploading) => {
    uploading(true)
    try {
        const config = {
            headers : {
                'Content-type': 'multipart/form-data'
            }
        }

        const { data } = await axios.post('/api/uploads', formData, config)
        func(data)
        uploading(false)
      } catch(error){
         console.error(error)
         uploading(false)
    }
}

export { uploadImage }