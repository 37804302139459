import React, {useEffect, useContext, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {Link} from 'react-router-dom'
import {LanguageContext } from '../context/languageContext'
import translate from '../language/translate'
import { registerNewUserActon } from '../actions/userActions'
import Alert from '../components/Alert'
import Loader from '../components/Loader'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

function RegisterUserScreen({history, location}) {
 const { pathname } = location
 const [name, setName] = useState('')
 const [email, setEmail] = useState('')
 const [password, setPassword] = useState('')
 const [confirmPassword, setConfirmPassword] = useState('')
 const [message, setMessage] = useState(null)
 const dispatch = useDispatch();

 const {language} = useContext(LanguageContext)
 const {
     register, 
    _name, 
    _email, 
    _password, 
    enterName, 
    enterEmail, 
    enterPassword, 
    _confirmPassword, 
    signIn,
    haveAccount, 
    matchPasswrod
} = translate[language]


const newUserRegister = useSelector(state => state.newUserRegister)
const {error, newUser, loading } = newUserRegister

useEffect(() => {
    if(newUser){
        history.push('/')
    }
}, [history, newUser])

 const handleSubmit = e => {
     e.preventDefault()
    if(password !== confirmPassword){
       setMessage(matchPasswrod)
    } else {
        dispatch(registerNewUserActon(name, email, password))
    }
 }

 return (
     <>
     <NavBar pathname={pathname}/>
        <div className='userRegister'>
            <div className='userRegister-minContainer'>
                <div className='userRegister-minContainer_line'></div>
                <h1 className={language === 'GEO' ? 'geoFont-Login' : ''}>
                    {register}
                </h1>
            </div>
            {message && <Alert text={message} success={false}/>}
            {error && <Alert text={error} success={false}/>}
            {loading && <Loader/>}
            <form onSubmit={handleSubmit} 
                  className={language === 'GEO' ? 'userRegister-form geoFont-Login' : 'userRegister-form'}>
              <label htmlFor='name'> {_name}</label>
                <input 
                    id='name'
                    value={name}
                    type='text' 
                    onChange={e => setName(e.target.value)} 
                    placeholder={enterName}/>
                <label htmlFor='email'> {_email}</label>
                <input 
                    id='email'
                    value={email}
                    type='text' 
                    onChange={e => setEmail(e.target.value)} 
                    placeholder={enterEmail}
                    />
                 <label htmlFor='password'> {_password}</label>
                <input 
                    id='password'
                    value={password} 
                    type='password'
                    onChange={e => setPassword(e.target.value)} 
                    placeholder={enterPassword}
                    />
                <label htmlFor='confirmPassword'> {_confirmPassword}</label>
                <input 
                    id='confirmPassword'
                    value={confirmPassword} 
                    type='password'
                    onChange={e => setConfirmPassword(e.target.value)} 
                    placeholder={_confirmPassword}
                    />
                 <button type='submit'>{register}</button>
              </form>
              <div className='userRegister-info'>
                     {haveAccount}
                     <Link to='/login'>{signIn}</Link>
              </div>
        </div>
        <Footer/>
      </>
    )
}

export default RegisterUserScreen
