import React, {useEffect, useState, useContext } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Alert from '../components/Alert'
import {Link} from 'react-router-dom'
import Loader from '../components/Loader'
import { createEventReviewAction } from '../actions/eventsActions'
import translate from '../language/translate'
import { LanguageContext} from '../context/languageContext'
import ReviewEditForm from './ReviewEditForm'
import '../styles/components/reviews.scss'

function EventReview({event, eventId}) {
 const { language } = useContext(LanguageContext)
 const { review, writeReview, submit, please, autorization, toWriteReview} = translate[language]
 const [comment, setComment] = useState('')

 const dispatch = useDispatch()

 const loginUser  = useSelector(state => state.loginUser)
 const { user } = loginUser

 const createEventReview = useSelector(state => state.createEventReview)
 const {error: reviewError, loading: reviewLoading, success } = createEventReview

 const removeReview = useSelector(state => state.removeReview)
 const { error: removeError, loading: removeLoading, success: removeSuccess} = removeReview

 const updateReview = useSelector(state => state.updateReview)
 const { error: updateError, success: updateSuccess} = updateReview

 useEffect(() => {
    if (success || updateSuccess) {
        setComment('')
    }
 }, [success, updateSuccess, removeSuccess])

 const handleSubmit = e => {
    e.preventDefault()
    dispatch(createEventReviewAction(eventId, {comment}))
}
 return (
        <div className='event_reviews'>
          {event && <div className='event_reviews-form'>
                <h2 className='event_reviews-title'>
                    {event.reviews ? <span>{event.reviews.length}</span> : ''} {review}, 
                    {user && <span>{'  '}{writeReview}</span>}
                </h2>
                {reviewError && <Alert success={false} text={reviewError}/>}
                {user ? <form onSubmit={handleSubmit}>
                            <textarea
                                id='comment' 
                                type='text' 
                                required
                                value={comment}
                                placeholder={writeReview} 
                                onChange={e => setComment(e.target.value)}/>
                            <button type='submit'>{submit}</button>
                     </form> : <p onClick={() => window.scrollTo({top: 0})}>
                                  {please} <Link to='/login'>{autorization}</Link> {toWriteReview}{' '}
                              </p> }
         </div>}
        {reviewLoading && <Loader/>}
        {removeLoading && <Loader/>}
        {removeError && <Alert text={removeError} success={false}/>}
        {updateError && <Alert text={updateError} success={false}/>}
        <div className={event.reviews && event.reviews.length && event.reviews.length >= 6 ? 'event_reviews-list scroll-review' : 'event_reviews-list'}>
            {event.reviews && event.reviews.map((review, index) => (
                <ReviewEditForm review={review} key={review._id} index={index} event={event} eventId={eventId}/>
            ))}
        </div>
    </div>
    )
}

export default EventReview
