import React, {useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import { Link } from 'react-router-dom'
import { LanguageContext } from '../context/languageContext'
import { blogListAction } from '../actions/blogsActions'
import '../styles/screens/blog.scss'
import Alert from '../components/Alert'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { BLOG_DETAIL_RESET } from '../constants/blogsConstants'
import translate from '../language/translate'

function BlogScreen({location}) {
  const { pathname } = location
  const { language } = useContext(LanguageContext)
  const { blog : _blog } = translate[language]
  const dispatch = useDispatch()

  const blogList = useSelector(state => state.blogList)
  const { blogs, error, loading } = blogList

  const singleBlog = useSelector(state => state.singleBlog)
  const { blog } = singleBlog

  useEffect(() => {
      if(!blogs.length){
          dispatch(blogListAction())
      }
      if(blog && blog.titleGeo){
          dispatch({
              type: BLOG_DETAIL_RESET
          })
      }
  }, [dispatch, blogs.length, blog])
    return (
     <>
      <NavBar pathname={pathname}/>
        <div className='blogs'>
         <div className='blogs_line'></div>
            <h1 className={language === 'GEO' ? 'geoFont-Login' : ''}>{_blog}</h1>
             {error && <Alert text={error} success={false}/>}
             {loading ? <Loader/> : <div className='blogs-container'>
                {blogs.map(blog => (
                    <div className='blogs-container-items scale-up' 
                        key={blog._id} 
                        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
                        <Link to={`/blogs/${blog._id}`}>
                         <img className='blogs-container-items-image' 
                              src={blog.image} alt={blog.titleGeo}
                              />
                        <p className='blogs-container-items-date'>
                            <FontAwesomeIcon icon={faCalendar}/>
                            <span>{blog.createdAt.substring(0, 10)}</span>
                        </p>
                        <h2 className={language === 'GEO' ? 
                                      'blogs-container-items-title geoFont-Login' 
                                      : 'blogs-container-items-title'}>
                            {language === 'GEO' ? blog.titleGeo : blog.titleEng}
                        </h2>
                        <p className='blogs-container-items-text'>
                            {language === 'GEO' ? 
                            `${blog.textGeo.substring(0, 90)}...` 
                            :`${blog.textEng.substring(0, 90)}...`}
                        </p>
                       </Link>
                    </div>
                ))}
            </div>}
        </div>
        <Footer/>
      </>
  )
}

export default BlogScreen
