import React, {useEffect, useContext} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import Loader from '../components/Loader'
import { singleBlogAction } from '../actions/blogsActions'
import { LanguageContext } from '../context/languageContext'
import '../styles/screens/singleBlog.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faCalendar } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

function BlogDetailScreen({match}) {
  const { language } = useContext(LanguageContext)
  const dispatch = useDispatch()
 
  const singleBlog = useSelector(state => state.singleBlog)
  const { error, loading, blog } = singleBlog

  useEffect(() => {
    if(blog === undefined || !blog._id || blog._id !== match.params.id){
      dispatch(singleBlogAction(match.params.id))
    }
  }, [dispatch, match.params.id, blog])
  const history = useHistory();
  const goToPreviousPath = () => {
        history.goBack()
  }
    return (
      <>
       <NavBar singleBlog={true}/>
        <div className='single-blog'>
            {error && error}
            {loading && <Loader/>}
             {blog && blog.image && <div className='single-blog_container'>
              <img className='single-blog_container-image' src={blog.image} alt={blog.titleEng}/>
                <div className='single-blog_container_small'>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} onClick={goToPreviousPath} className='single-blog_container_small-back'/>
                  <h2 className={language === 'GEO' ? 
                      'single-blog_container_small-title geoFont-Login' 
                      :'single-blog_container_small-title'}>
                    {language === 'GEO' ? blog.titleGeo : blog.titleEng}
                  </h2>
                  <p className='single-blog_container_small-text'>
                      {language === 'GEO' ? blog.textGeo : blog.textEng}
                  </p>
                  <p className='single-blog_container_small-date'>
                        <FontAwesomeIcon icon={faCalendar}/>
                        {blog.createdAt && <span>{blog.createdAt.substring(0, 10)}</span>}
                  </p>
                </div>
            </div>
            }
        </div>
        <Footer/>
        </>
    )
}

export default BlogDetailScreen
