import React, { useContext } from 'react'
import { LanguageContext } from '../context/languageContext'
import '../styles/components/obstacleSport.scss'
import { obstacleSport } from '../helpers/staticContent'

function ObstacleSports() {
  const { language } = useContext(LanguageContext)
    return (
        <section id='obstacle-sports' className='obstacle-sports'>
          <div className='obstacle-sports-minContainer'>
              <div className='obstacle-sports-minContainer-line'></div>
              <h2>Obsatcle Sports</h2>
          </div>
           <div className='obstacle-sports-textContainer'>
               <p>
                 {language === 'GEO' ? obstacleSport.textGeo : obstacleSport.textEng }
               </p>
           </div>
      </section>
    )
}

export default ObstacleSports
