import { 
  EVENT_CREATE_FAIL,
  EVENT_CREATE_REQUEST,
  EVENT_CREATE_RESET,
  EVENT_CREATE_REVIEW_FAIL,
  EVENT_CREATE_REVIEW_REQUEST,
  EVENT_CREATE_REVIEW_RESET,
  EVENT_CREATE_REVIEW_SUCCESS,
  EVENT_CREATE_SUCCESS,
  EVENT_DETAIL_FAIL, 
  EVENT_DETAIL_REQUEST, 
  EVENT_DETAIL_RESET, 
  EVENT_DETAIL_SUCCESS, 
  EVENT_LIST_FAIL, 
  EVENT_LIST_REQUEST, 
  EVENT_LIST_SUCCESS, 
  EVENT_REMOVE_FAIL, 
  EVENT_REMOVE_REQUEST,
  EVENT_REMOVE_SUCCESS,
  EVENT_UPDATE_FAIL,
  EVENT_UPDATE_REQUEST,
  EVENT_UPDATE_RESET,
  EVENT_UPDATE_SUCCESS
} from "../constants/eventsConstants";

export const eventsListReducer = ( state = { events : []}, action) => {
  switch(action.type){
      case EVENT_LIST_REQUEST : 
        return {
            loading: true,
            events: []
        }
      case EVENT_LIST_SUCCESS : 
        return {
            loading: false,
            events: action.payload
        }
      case EVENT_LIST_FAIL:
        return {
            loading: false,
            error: action.payload
        }
      default :
         return state
  }
}

export const singleEventReducer = ( state = { event : { reviews: []} }, action) => {
  switch(action.type){
      case EVENT_DETAIL_REQUEST : 
        return {
            loading: true,
            ...state
        }
      case EVENT_DETAIL_SUCCESS : 
        return {
            loading: false,
            event: action.payload
        }
      case EVENT_DETAIL_FAIL:
        return {
            loading: false,
            error: action.payload
        }
      case EVENT_DETAIL_RESET :
        return {
           event : { reviews : []}
        }
      default :
         return state
  }
}

export const removeEventReducer = ( state = {}, action) => {
  switch(action.type){
      case EVENT_REMOVE_REQUEST : 
        return {
            loading: true,
        }
      case EVENT_REMOVE_SUCCESS : 
        return {
            loading: false,
            success: true
        }
      case EVENT_REMOVE_FAIL:
        return {
            loading: false,
            error: action.payload
        }
      default :
         return state
  }
}

export const createEventReducer = ( state = { event: {}}, action) => {
  switch(action.type){
      case EVENT_CREATE_REQUEST : 
        return {
            loading: true,
        }
      case EVENT_CREATE_SUCCESS : 
        return {
            loading: false,
            success: true
        }
      case EVENT_CREATE_FAIL:
        return {
            loading: false,
            error: action.payload
        }
      case EVENT_CREATE_RESET:
        return { event: {}}
      default :
         return state
  }
}

export const updateEventReducer = ( state = { event: {}}, action) => {
  switch(action.type){
      case EVENT_UPDATE_REQUEST : 
        return {
            loading: true,
        }
      case EVENT_UPDATE_SUCCESS : 
        return {
            loading: false,
            success: true,
            event: action.payload
        }
      case EVENT_UPDATE_FAIL:
        return {
            loading: false,
            error: action.payload
        }
      case EVENT_UPDATE_RESET:
        return { event: {}}
      default :
         return state
  }
}

export const createEventReviewReducer = ( state = {}, action) => {
  switch(action.type){
      case EVENT_CREATE_REVIEW_REQUEST : 
        return {
            loading: true,
        }
      case EVENT_CREATE_REVIEW_SUCCESS : 
        return {
            loading: false,
            success: true
        }
      case EVENT_CREATE_REVIEW_FAIL:
        return {
            loading: false,
            error: action.payload
        }
      case EVENT_CREATE_REVIEW_RESET:
        return {}
      default :
         return state
  }
}