import axios from "axios"
import { 
    BLOG_CREATE_FAIL,
    BLOG_CREATE_REQUEST,
    BLOG_CREATE_SUCCESS,
    BLOG_DETAIL_FAIL, 
    BLOG_DETAIL_REQUEST, 
    BLOG_DETAIL_SUCCESS, 
    BLOG_LIST_FAIL, 
    BLOG_LIST_REQUEST, 
    BLOG_LIST_SUCCESS, 
    BLOG_REMOVE_FAIL, 
    BLOG_REMOVE_REQUEST,
    BLOG_REMOVE_SUCCESS,
    BLOG_UPDATE_FAIL,
    BLOG_UPDATE_REQUEST,
    BLOG_UPDATE_SUCCESS
} from "../constants/blogsConstants"

export const blogListAction = () => async(dispatch) => {
   try {
      dispatch({
          type: BLOG_LIST_REQUEST
      })

      const { data } = await axios.get('/api/blogs')

      dispatch({
          type: BLOG_LIST_SUCCESS,
          payload: data
      })
   }
   catch(error){
    dispatch({
        type: BLOG_LIST_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
   }
}

export const singleBlogAction = (id) => async(dispatch) => {
    try {
        dispatch({
            type: BLOG_DETAIL_REQUEST
        })
   
        const { data } = await axios.get(`/api/blogs/${id}`)
   
        dispatch({
            type: BLOG_DETAIL_SUCCESS,
            payload: data
        })
      }
      catch(error){
       dispatch({
           type: BLOG_DETAIL_FAIL,
           payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })
  }
}

export const removeBlogAction = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: BLOG_REMOVE_REQUEST
        })
   
        const { loginUser : { user } } = getState()
   
        const config = {
            headers : {
              Authorization: `Bearer ${user.token}`
            }
        }
   
        await axios.delete(`/api/blogs/${id}`, config)
   
        dispatch({ type: BLOG_REMOVE_SUCCESS })

     } catch(error){
        dispatch({
            type: BLOG_REMOVE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
     }
  }

export const createBlogAction = (titleEng, titleGeo, textEng, textGeo, image) => async(dispatch, getState) => {
    try {
        dispatch({
            type: BLOG_CREATE_REQUEST
        })
   
        const { loginUser : { user } } = getState()
   
        const config = {
            headers : {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${user.token}`
            }
        }
   
        await axios.post(`/api/blogs/`, {titleEng, titleGeo, textEng, textGeo, image}, config)
   
        dispatch({ type: BLOG_CREATE_SUCCESS })

     } catch(error){
        dispatch({
            type: BLOG_CREATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
     }
}

export const updateBlogAction = (blogObj) => async(dispatch, getState) => {
    try {
        dispatch({
            type: BLOG_UPDATE_REQUEST
        })
   
        const { loginUser : { user } } = getState()
   
        const config = {
            headers : {
             'Content-Type': 'application/json',
              Authorization: `Bearer ${user.token}`
            }
        }
   
        const { data } = await axios.put(`/api/blogs/${blogObj._id}`, blogObj, config)
   
        dispatch({  type: BLOG_UPDATE_SUCCESS })

        dispatch({ type: BLOG_DETAIL_SUCCESS, payload: data})

     } catch(error){
        dispatch({
            type: BLOG_UPDATE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        })
     }
}