import React from 'react'
import '../styles/components/alert.scss'

function Alert({text, success, info}) {
    return (
        <div style={{
            backgroundColor : success ? "#13883e" : !success ?  "#b73326" : info ? 'yellow' : ''
            }} className='alert'>
            <h2>{text}</h2>
        </div>
    )
}



export default Alert
