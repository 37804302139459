import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Alert from '../components/Alert'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {uploadImage} from '../helpers/upload'
import { createEventAction } from '../actions/eventsActions'
import { EVENT_CREATE_RESET } from '../constants/eventsConstants'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

function AdminCreateEventScreen({history}) {
    const [titleGeo, setTitleGeo] = useState('')
    const [titleEng, setTitleEng] = useState('')
    const [textGeo, setTextGeo] = useState('')
    const [textEng, setTextEng] = useState('')
    const [image, setImage] = useState('')
    const [uploading, setUploading] = useState(false)
    const dispatch = useDispatch()

    const loginUser = useSelector(state => state.loginUser)
    const { user } = loginUser

    const createEvent = useSelector(state => state.createEvent)
    const { error, success, loading } = createEvent

    useEffect(() => {
        if(user && user.isAdmin){
             if(success){
                 history.push('/eventList')
                 dispatch({ type: EVENT_CREATE_RESET})
             }
        } else {
           history.push('/')
        }
    }, [history, user, success, dispatch])

    const handleSubmit = e => {
        e.preventDefault()
        dispatch(createEventAction(
            titleEng,
            titleGeo,
            textEng,
            textGeo,
            image
        ))
    }

    const handleUpload = async(e) => {
       const file = e.target.files[0]
       const formData = new FormData()
       formData.append('image', file)
       uploadImage(formData, setImage, setUploading)
    }
    return (
        <>
        <NavBar/>
        <div className='form_event'>
            <h1>Create New Event</h1>
            {error && <Alert text={error} success={false}/>}
            {loading && <Loader/>}
            <div className='form_event-container'>
                <Link to='/eventList' className='form_event-container-back'>
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </Link>
                <form onSubmit={handleSubmit} className="form_event-container-form">

                    <label htmlFor='titleGeo'>ქართული სათაური</label>
                    <input id='titleGeo' type="text" value={titleGeo} 
                           placeholder='ქართული სათაური' required onChange={(e) => setTitleGeo(e.target.value)}/>

                    <label htmlFor='titleEng'>English Title</label>
                    <input id='titleEng' type="text" value={titleEng} 
                           placeholder='English Title' required onChange={(e) => setTitleEng(e.target.value)}/>

                    <label htmlFor='textGeo'>ქართული ტექსტი</label>
                    <textarea id='textGeo' type="text" value={textGeo} 
                           placeholder='ქართული ტექსტი' required onChange={(e) => setTextGeo(e.target.value)}/>

                    <label htmlFor='textEng'>English Text</label>
                    <textarea id='textEng' type="text" value={textEng} 
                           placeholder='English Title' required onChange={(e) => setTextEng(e.target.value)}/>

                    <label htmlFor='image'>Image Url</label>
                    <input type="text" id='image' 
                           value={image} placeholder='Enter Image Url' 
                           onChange={e => setImage(e.target.value)}/>

                    <label htmlFor='image-file'>Upload Image</label>
                    <input type="file" id='image-file' onChange={handleUpload}/>
                     {uploading && <Loader/>}
                    <button type="submit">Create</button>
                </form>
            </div>
        </div>
       <Footer/>
      </>
    )
}

export default AdminCreateEventScreen
