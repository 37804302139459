import React, {useState, createContext} from 'react'

export const LanguageContext = createContext()

export const LanguageProvider = ({children}) =>{
    const [language, setLanguage] = useState(JSON.parse(window.localStorage.getItem('language')) || 'GEO')
    const handleChange = value => {
        setLanguage(value)
    }
    return (
        <LanguageContext.Provider value={{language, handleChange}}>
             {children}
        </LanguageContext.Provider>
    )
}