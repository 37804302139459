import React, {useEffect, useState} from 'react'
import Loader from '../components/Loader'
import Alert from '../components/Alert'
import { Link } from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { userListAction } from '../actions/userActions'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCheck, faEdit, faTimes, faTrash} from '@fortawesome/free-solid-svg-icons'
import { removeUser } from '../actions/userActions'
import '../styles/screens/user.scss'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

function UserListScreen({history}) {
  const dispatch = useDispatch()
  const [userEmail, setUserEmail] = useState('')
  const [userFound, setUserFound] = useState([])
  const [message, setMessage] = useState(null)

  const loginUser = useSelector(state => state.loginUser)
  const { user } = loginUser

  const userList = useSelector(state => state.userList)
  const {error, loading, users } = userList

  const userRemove = useSelector(state => state.userRemove)
  
  const { success, loading: loadingRemove } = userRemove

  useEffect(() => {
      if((user && user.isAdmin) || user) {
          dispatch(userListAction())
        } else {
          history.push('/')
      }
  }, [user, history, dispatch, success])

  const handleSubmit = e => {
    e.preventDefault();
   let filteredUser;
   filteredUser = users.filter(user => user.email === userEmail)
   if(filteredUser.length){
       setUserFound(filteredUser)
       setMessage(null)
   } else {
       setUserFound([])
       setMessage('user Not Found')
   }
   setUserEmail('')
  }

  const deleteHandler = id => {
      dispatch(removeUser(id))
      setUserFound([])
  }
    return (
       <>
       <NavBar/>
        <div className='List'>
            <h2>User List</h2>
            {error && <Alert text={error} success={false}/>}
            {loading || loadingRemove? <Loader/> 
            :<div className='List-container'>
                <table className='List-container-table'>
               <thead>
                 <tr>
                    <th>ID</th>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>ADMIN</th>
                    <th></th>
                 </tr>
                </thead>
                <tbody>
               {users.map(user => (
                   <tr key={user._id}>
                       <td>{user._id}</td>
                       <td>{user.name}</td>
                       <td>
                         <a href={`mailto:${user.email}`}>{user.email}</a>
                        </td>
                       <td>
                           <FontAwesomeIcon icon={user.isAdmin ? faCheck : faTimes}/>
                       </td>
                       <td>
                           {user.isAdmin ? <Link to={`/user/profile/${user._id}/edit`}><FontAwesomeIcon icon={faEdit}/></Link>
                           : 
                           <> 
                             <Link to={`/user/profile/${user._id}/edit`}><FontAwesomeIcon icon={faEdit}/></Link>
                             <FontAwesomeIcon icon={faTrash} onClick={() => deleteHandler(user._id)}/>
                           </>
                           }
                       </td>
                   </tr>
               ))}
               </tbody>
              </table>
             </div>
            }
            <div className='List-find'>
                <h2>Find User By Email</h2>
                <form onSubmit={handleSubmit}>
                    <input 
                    type='text' 
                    value={userEmail} 
                    onChange={e => setUserEmail(e.target.value)} 
                    placeholder='search user by email'/>
                    <button type='submit'>Search</button>
                </form>
                {message && <Alert text={message} success={false}/>}
                {userFound.length > 0 && 
                <>
                 <FontAwesomeIcon icon={faTimes} onClick={() => setUserFound('')} style={{cursor: 'pointer', fontSize: '1.2rem'}}/>
                <table className='List-container-table'>
                <thead>
                 <tr>
                    <th>ID</th>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>ADMIN</th>
                    <th></th>
                 </tr>
                 </thead>
                 <tbody>
               {userFound.map(user => (
                   <tr key={user._id}>
                       <td>{user._id}</td>
                       <td>{user.name}</td>
                       <td>
                         <a href={`mailto:${user.email}`}>{user.email}</a>
                        </td>
                       <td>
                           <FontAwesomeIcon icon={user.isAdmin ? faCheck : faTimes} style={{cursor: 'pointer'}}/>
                       </td>
                       <td>
                       {user.isAdmin ? <Link to={`/user/profile/${user._id}/edit`}><FontAwesomeIcon icon={faEdit}/></Link> 
                           : <> 
                           <Link to={`/user/profile/${user._id}/edit`}><FontAwesomeIcon icon={faEdit}/></Link>
                           <FontAwesomeIcon 
                            icon={faTrash} 
                            onClick={() => deleteHandler(user._id)}
                            style={{cursor: 'pointer'}}
                            />
                           </>}
                       </td>
                   </tr>
               ))}
               </tbody>
              </table></>}
            </div>
        </div>
       <Footer/>
      </>
    )
}

export default UserListScreen
