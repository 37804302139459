import React, {useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../components/Alert'
import {Link} from 'react-router-dom'
import {loginUserActon} from '../actions/userActions'
import { LanguageContext } from '../context/languageContext'
import '../styles/screens/user.scss'
import Loader from '../components/Loader'
import translate from '../language/translate'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'

function UserLoginScreen({history, location}) {
 const {pathname} = location
 const { language } = useContext(LanguageContext)
 const { register, newCostumer, enterPassword, _password, _email, signIn, enterEmail } = translate[language]
 const [email, setEmail] = useState('');
 const [password, setPassword] = useState('')

 const dispatch = useDispatch()

 const loginUser = useSelector(state => state.loginUser)
 const {loading, error, user,} = loginUser

 const redirect = location.search ? location.search.split('=')[1] : '/'

 useEffect(() => {
     if(user){
         history.push(redirect)
     }
 }, [user, history, redirect])

 const handleSubmit = (e) => {
     e.preventDefault()
     dispatch(loginUserActon(email, password))
 }

    return (
        <>
        <NavBar pathname={pathname}/>
        <div className='userLogin'>
            <div className="userLogin-minContainer">
                <div className='userLogin-minContainer_line'></div>
                <h1 className={language === 'GEO' ? 'geoFont-Login' : ''}>
                    {signIn}
                </h1>
            </div>
             {error && <Alert text={error} success={false}/>}
             {loading && <Loader/> }
              <form onSubmit={handleSubmit} 
                    className={language === 'GEO' ? 'userLogin-form geoFont-Login' : 'userLogin-form'}>
                <label htmlFor='email'>{_email}</label>
                <input 
                    id='email'
                    value={email}
                    type='text' 
                    onChange={e => setEmail(e.target.value)} 
                    placeholder={enterEmail}/>
                 <label htmlFor='password'> {_password}</label>
                <input 
                    id='password'
                    value={password} 
                    type='password'
                    onChange={e => setPassword(e.target.value)} 
                    placeholder={enterPassword}/>
                 <button>{signIn}</button>
              </form>
              <div className='userLogin-info'>
                     {newCostumer}
                     <Link to='/register'>{register}</Link>
              </div>
        </div>
        <Footer/>
      </>
    )
}

export default UserLoginScreen
