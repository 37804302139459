import React, {useEffect, useContext, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {blogListAction, removeBlogAction } from '../actions/blogsActions'
import {LanguageContext} from '../context/languageContext'
import {Link} from 'react-router-dom'

import Alert from '../components/Alert'
import Loader from '../components/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'

function AdminBlogsListScreen({history}) {
    const {language} = useContext(LanguageContext)
    const dispatch = useDispatch()
    const [blogTitle, setBlogTitle] = useState('')
    const [blogFound, setBlogFound] = useState([])
    const [message, setMessage] = useState(null)
  
    const loginUser = useSelector(state => state.loginUser)
    const { user } = loginUser

    const blogList = useSelector(state => state.blogList)
    const { error, loading, blogs} = blogList
  
    const removeBlog = useSelector(state => state.removeBlog)
    const { success, loading: loadingRemove } = removeBlog

    useEffect(() => {
        if(user && user.isAdmin) {
            dispatch(blogListAction())
          } else {
            history.push('/')
        }
    }, [user, history, dispatch, success])
  
    const handleSubmit = e => {
      e.preventDefault();
     let filteredBlog;
     filteredBlog = blogs.filter(
         blog => blog.titleGeo.toLowerCase() === blogTitle.toLowerCase() ||
         blog.titleEng.toLowerCase() === blogTitle.toLowerCase()
        )
     if(filteredBlog.length){
         setBlogFound(filteredBlog)
         setMessage(null)
     } else {
         setBlogFound([])
         setMessage('Blog Not Found')
     }
     setBlogTitle('')
    }
  
    const deleteHandler = id => {
        dispatch(removeBlogAction(id))
        setBlogFound([])
    }
      return (
          <>
          <NavBar/>
          <div className='blog_List'>
              <h2>Blog List</h2>
              {error && <Alert text={error} success={false}/>}
              {loading || loadingRemove ? <Loader/> 
              :<div className='blog_List-container'>
                  <div className='blog_List-container-createBtn'>
                      <Link to='/blog/create'>
                         <FontAwesomeIcon icon={faPlus}/> Create Blog
                      </Link>
                  </div>
                  <table className='blog_List-container-table'>
                 <thead>
                   <tr>
                      <th>TITLE</th>
                      <th>IMAGE</th>
                      <th>TEXT</th>
                      <th>CREATED</th>
                      <th></th>
                   </tr>
                  </thead>
                  <tbody>
                 {blogs.map(blog => (
                     <tr key={blog._id}>
                         <td>
                         <Link to={`/blogs/${blog._id}`}> 
                           {language === 'GEO' ? blog.titleGeo : blog.titleEng} 
                         </Link>
                        </td>
                         <td>
                           <img src={blog.image} alt={blog.titleEng} style={{width: '5rem'}}/>
                          </td>
                         <td>
                         {language === 'GEO'  ? 
                            `${blog.textGeo.substring(0, 90)}...`
                            : blog.textEng.substring(0, 90)}...
                         </td>
                         <td>
                            {blog.createdAt.substring(0, 10)}
                         </td>
                         <td>
                           <Link to={`/blogs/${blog._id}/edit`}><FontAwesomeIcon icon={faEdit}/></Link>
                           <FontAwesomeIcon 
                            icon={faTrash} 
                            onClick={() => deleteHandler(blog._id)}
                            style={{cursor: 'pointer', marginLeft: '1rem'}}
                            />
                         </td>
                     </tr>
                 ))}
                 </tbody>
                </table>
               </div>
              }
              <div className='blog_List-find'>
                  <h2>Find Blog By Title</h2>
                  <form onSubmit={handleSubmit}>
                      <input 
                      type='text' 
                      value={blogTitle} 
                      onChange={e => setBlogTitle(e.target.value)} 
                      placeholder='search blog by title'/>
                      <button type='submit'>Search</button>
                  </form>
                  {message && <Alert text={message} success={false}/>}
                  {blogFound.length > 0 && 
                  <>
                   <FontAwesomeIcon icon={faTimes} onClick={() => setBlogFound('')} style={{cursor: 'pointer', fontSize: '1.2rem'}}/>
                  <table className='blog_List-container-table'>
                  <thead>
                   <tr>
                      <th>TITLE</th>
                      <th>IMAGE</th>
                      <th>TEXT</th>
                      <th>CREATED</th>
                      <th></th>
                   </tr>
                   </thead>
                   <tbody>
                 {blogFound.map(blog => (
                     <tr key={blog._id}>
                         <td>
                         <Link to={`/blogs/${blog._id}`}> 
                           {language === 'GEO' ? blog.titleGeo : blog.titleEng} 
                         </Link>
                         </td>
                         <td>
                          <img src={blog.image} alt={blog.titleEng} style={{width: '5rem'}}/>
                         </td>
                         <td>
                           {language === 'GEO'  ? blog.textGeo : blog.textEng}
                          </td>
                          <th>
                              {blog.createdAt.substring(0, 10)}
                          </th>
                         <td>
                          <Link to={`/blogs/${blog._id}/edit`}><FontAwesomeIcon icon={faEdit}/></Link>
                           <FontAwesomeIcon 
                            icon={faTrash} 
                            onClick={() => deleteHandler(blog._id)}
                            style={{cursor: 'pointer', marginLeft: '1rem'}}
                            />
                         </td>
                     </tr>
                 ))}
                 </tbody>
                </table></>}
              </div>
          </div>
        <Footer/>
     </>
    )
}

export default AdminBlogsListScreen
