import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { blogListReducer, createBlogReducer, removeBlogReducer, singleBlogReducer, updateBlogReducer } from '../reducers/blogsReducers'
import { createEventReducer, createEventReviewReducer, eventsListReducer, removeEventReducer, singleEventReducer, updateEventReducer } from '../reducers/eventsReducers'
import { removeReviewReducer, updateReviewReducer } from '../reducers/reviewsReducer'
import { 
  loginUserReducer, 
  newUserRegisterReducer, 
  userAdminUpdateReducer, 
  userDetailReducer, 
  userListReducer, 
  userRemoveReducer, 
  userUpdateReducer 
} from '../reducers/userReducers'

const reducer = combineReducers({
  eventsList : eventsListReducer,
  singleEvent : singleEventReducer,
  removeEvent: removeEventReducer,
  createEvent: createEventReducer,
  updateEvent: updateEventReducer,
  createEventReview: createEventReviewReducer,
  blogList: blogListReducer,
  singleBlog: singleBlogReducer,
  createBlog: createBlogReducer,
  updateBlog: updateBlogReducer,
  removeBlog: removeBlogReducer,
  loginUser: loginUserReducer,
  newUserRegister: newUserRegisterReducer,
  userList: userListReducer,
  userDetail: userDetailReducer,
  userUpdate: userUpdateReducer,
  userRemove: userRemoveReducer,
  userAdminUpdate: userAdminUpdateReducer,
  removeReview: removeReviewReducer,
  updateReview: updateReviewReducer
})

const loginUserFromStorage = window.localStorage.getItem('user') 
? JSON.parse(window.localStorage.getItem('user')) : null

const initialState = {
  loginUser : {
    user: loginUserFromStorage
  }
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store;