import React, {useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { getEventsList } from '../actions/eventsActions'
import '../styles/screens/event.scss'
import { LanguageContext } from '../context/languageContext'
import translate from '../language/translate'
import Loader from '../components/Loader'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Alert from '../components/Alert'
import { EVENT_DETAIL_RESET } from '../constants/eventsConstants'
import SearchBox from '../components/SearchBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

function EventsScreen({location, match}) {
    const keyword = match.params.keyword
    const { pathname } = location
    const { language } = useContext(LanguageContext)
    const { noSearchResultFound, event: _event, seeDetail } = translate[language]
    const dispatch = useDispatch()

    const eventsList = useSelector(state => state.eventsList)
    const { loading, events, error } = eventsList

    const singleEvent = useSelector(state => state.singleEvent)
    const { event } = singleEvent
    useEffect(() => {
        if(!events.length){
            dispatch(getEventsList(keyword, language))
        }
    
        if(event && event.titleEng){
            dispatch({
                type: EVENT_DETAIL_RESET
            })
        }
    }, [dispatch, event, keyword, language, events.length])

    const noSearchResult = (keyword && events.length === 0 && !loading) || ''
    return (
        <>
        <NavBar pathname={pathname}/>
        <div className={noSearchResult ? 'no-result-height event' : 'event'}>
        <div className='event_line'></div>
            <h1 className={language === 'GEO' ? 'geoFont-Login' : ''}>{_event}</h1>
            {noSearchResult ? <Alert text={noSearchResultFound} success={false}/> : ''}
            <Route render={({history}) => <SearchBox history={history}/>}/>
            {error && {error}}
            {loading ? <Loader/> : <div className='event-container'>
            {events && events.length > 0 && events.map(ev => (
                <div className='event-container-items scale-up' 
                     onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                     key={ev._id}>
                    <Link to={`/events/${ev._id}`}>
                    <img className='event-container-items-image' src={ev.image} alt={ev.titleGeo || ev.titleEng}/>
                        <div className='event-container-items-detail'>
                           <span className='event-container-items-detail-date'>
                            {ev.createdAt.substring(0, 10)}
                           </span>
                            <button className={language === 'GEO' 
                            ? 'event-container-items-detail-btn geoFont-Login' 
                            : 'event-container-items-detail-btn engFont-Nav'}>
                                {seeDetail}
                                <FontAwesomeIcon icon={faArrowRight}/>
                            </button>
                        </div>
                   </Link>
                    <h3 className={language === 'GEO' 
                        ? 'event-container-items-title geoFont-Login' 
                        : 'event-container-items-title engFont-Nav'}>
                     {language === 'GEO' ? ev.titleGeo : ev.titleEng}
                    </h3>
                    <p className={language === 'GEO' 
                        ? 'event-container-items-text geoFont-Login' 
                        : 'event-container-items-text engFont-Nav'}>
                      {language === 'GEO' ? ev.textGeo.substring(0, 90) : ev.textEng.substring(0, +90)}
                    </p>
                </div>
            ))}
            </div>}
        </div>
        <Footer/>
      </>
    )
}

export default EventsScreen
